import styled, { keyframes } from "styled-components"
import { AppLoadingStatus } from "../../App"
import React, { useEffect, useRef, useState } from "react"
import { Button, Form } from 'react-bootstrap'
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { BackendServiceError, cognitoLogin, connectProfile, createNewUser, DataPrivacyDoc, getUserById, loginWithPassword, PresenceType, ProfileResponse, registerProfile, updateUserValues, UserResponse, syncFavorites as syncFavoritesBackend, createUnreadCounterUser } from "../../backendServices/BackendServices"
import { useLanguageState } from "../../globalStates/LanguageState"
import reactStringReplace from 'react-string-replace'
import branding, { LocalizedString } from "../../branding/branding"
import moment from "moment"

import ErrorSite from "../../contentArea/errorPages/ErrorSite"
import CenteredLoader from "../../ui/CenteredLoader"
import LanguageSelector from "../../ui/LanguageSelector"
import LanguageToggler from "../../ui/LanguageToggle"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useHistory } from "react-router-dom"
import { defaultLogger as logger } from "../../globalStates/AppState";
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import MessagePanel from "../../ui/MessagePanel"

export interface LoginRegistrationSiteBranding {
    loginWithPassword: boolean
    loginPageType: LoginLayoutType
    submitEmailButton: LocalizedString
    emailPlaceHolder: LocalizedString
    passwordPlaceHolder: LocalizedString
    submitTokenButton: LocalizedString
    tokenPlaceholder: LocalizedString
    invalidToken: LocalizedString
    wrongToken: LocalizedString
    createNewToken: LocalizedString
    noToken: LocalizedString
    resendToken: LocalizedString
    ticketPanel: LocalizedString
    buyTicketButton: LocalizedString
    changeEmailButton: LocalizedString
    createTokenFailed: LocalizedString
    createTokenSuccess: LocalizedString
    dataPrivacyPanel: LocalizedString
    dataPolicy: LocalizedString
    invalidEmailPanel: LocalizedString
    invalidEmailPanelWithLink: {
        visible: boolean
        befLinkPart: LocalizedString
        linkPart: {
            link: LocalizedString
            text: LocalizedString
            textColor: string
        },
        aftLinkPart: LocalizedString
    }
    outdatedPrivacyDocsPanel: LocalizedString
    wrongPasswordPanel: LocalizedString
    registerEmailFailed: LocalizedString
    thirdPartyUserNotFound: LocalizedString
    forgotPassword: LocalizedString
    enterEmail: LocalizedString
    enterPassword: LocalizedString
    enterToken: LocalizedString
    centerLoginStyleTitle: LocalizedString
    centerLoginStyleTitleCode: LocalizedString
    centerLoginStyleSubtitle: LocalizedString
    centerLoginStyleTicketLink: LocalizedString
    loginRegistrationTitle: LocalizedString
    loginRegistrationSubtitle: LocalizedString
    logoutReasonSessionTimeout: LocalizedString,
    logoutReasonForbidden: LocalizedString,
    loginBackgroundColor1: string
    loginBackgroundColor2: string
    loginInputColor: string
    getTicketsLink: string
    passwordForgottenUrl: LocalizedString
    termsOfUseText: LocalizedString
    termsOfUseUrl: LocalizedString
    notSupportedScreenSize: LocalizedString
    centerLoginStyleTicketBorderBtn: string
    centerLoginStyleTicketTextColorBtn: string
    centerLoginStyleTicketBtnOnHoverBgColor: string
    centerLoginStyleTicketBtnOnHoverTextColor: string
    centerLoginSystemCheckBackgroundColor: string
    centerLoginStyleOverlay: string
    centerLoginLogoMaxWidth: string
    centerLoginLogoMaxHeight: string
    errorSiteButtonColor1: string
    errorSiteButtonColor2: string
    emailQueryParam: string
    passwordQueryParam: string
    showImprintAndPrivacyPolicyInFooter: boolean
    imprintLink: LocalizedString
    imprintLinkUrl: LocalizedString
    privacyPolicyLink: LocalizedString
    privacyPolicyLinkUrl: LocalizedString
    loginTitleTextColor: string
    loginSubTitleTextColor: string
    loginModalTextColor: string
    loginModalSubtitleTextColor: string
    loginInputMailTextColor: string
    loginInputMailPlaceholderColor: string
    loginBoxOverlayColor: string
    changeEmailAddressLinkColor: string
}

const LoginRegistrationStylePanel = styled.div`
    background-color: rgb(0,0,0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:  rgb(255,255,255);
    overflow: hidden;
    transition: right 0.2s ease;

    .siteAnimation-enter & {
        right: 0%;
        transition: right 0.2s ease;
        transition-delay: 1.3s;
    }
    .siteAnimation-enter-active & {
        right: 50%;
    }

    .siteAnimation-exit & {
        right: 50%;
        transition: right 0.2s ease;
    }
    .siteAnimation-exit-active & {
        right: 0;
    }

    &.fullWidth {
        right: 0;
    }
`

const LoginRegistrationStylePanelTitle = styled.div`
    z-index: 4;
    font-size: 60px;
    font-family: ${branding.font2};
`
const LoginRegistrationStylePanelSubTitle = styled.div`
    z-index: 4;
    font-family: ${branding.font2}, sans-serif;
    letter-spacing: 0.7em;
    margin-right: -0.7em;
    font-size: 40px;
    font-weight: 100;
`


const opacityAnimation = keyframes`
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 0.45;
    }
`

const zoomInAnimation = keyframes`
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.5);
    }
`

const LoginRegistrationStylePanelBackground0 = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -50%;
    background: linear-gradient(176deg, ${branding.loginRegistrationSite.loginBackgroundColor1} 0%, ${branding.loginRegistrationSite.loginBackgroundColor2} 100%);
    z-index: 1;
`

const LoginRegistrationStylePanelBackground1 = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.3;
    background: url("/branding/login-bg.jpg");
    background-blend-mode: hue;
    background-size: cover;
    z-index: 2;
    animation-name: ${zoomInAnimation};
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
`

const LoginRegistrationStylePanelBackground2 = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;
    background: url("./branding/banner.jpg");
    background-size: cover;
    z-index: 11;
    animation-name: ${opacityAnimation};
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
`


const LoginRegistrationSiteRoot = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    &.siteAnimation-enter {
        opacity: 0;
        transition: opacity 1.1s ease-in-out;
    }
    &.siteAnimation-enter-active {
        opacity: 1;
    }

    &.siteAnimation-exit {
        opacity: 1;
        transition: opacity 1.1s ease-in-out;
        transition-delay: 0.4s;
    }
    &.siteAnimation-exit-active {
        opacity: 0;
    }
    &.siteAnimation-exit-done {
        display: none;
    }
`


const LoginRegistrationFunctionPanel = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
`


const loginStepTransitionName = "loginStepAnimation"
const cssAnimationDuration = "0.6s";
const animationDuration = 600;

export const slideInFromEast = keyframes`
    0% {
        opacity: 0;
        transform: translateX(1000px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`

export const slideInFromWest = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-1000px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`

const LoginRegistrationFunctionContainer = styled.div`
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: auto;
    padding: 50px 0 50px 0;
`

const AnimatedLoginStepItem = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &.${loginStepTransitionName}-enter-active {
        animation-duration: ${cssAnimationDuration};
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-play-state: running;
    } 
    &.${loginStepTransitionName}-exit-active {
        animation-duration: ${cssAnimationDuration};
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: reverse;
        animation-fill-mode: forwards;
        animation-play-state: running;
    }
    &.dir-east.${loginStepTransitionName}-enter-active {
        animation-name: ${slideInFromWest};
    } 
    &.dir-east.${loginStepTransitionName}-exit-active {
        animation-name: ${slideInFromWest};
    }
    &.dir-west.${loginStepTransitionName}-enter-active {
        animation-name: ${slideInFromEast};
    } 
    &.dir-west.${loginStepTransitionName}-exit-active {
        animation-name: ${slideInFromEast};
    }
`


const LoginRegistrationInputPanel = styled.div`
    width: 50%;
    max-width: 300px;
    min-width: 200px;
`


const EventLogo = styled.img`
    margin-bottom: 35px;
    width: 50%;
    max-width: 300px;
    min-width: 200px;
`

const LoginInput = styled(Form.Control)`
    height: 40px;
    border-radius: 20px;
    max-width: 300px;

    border-color: ${branding.loginRegistrationSite.loginInputColor};

    color: rgb(0,0,0);
    text-align: center;
    &::placeholder {
        color: ${branding.loginRegistrationSite.loginInputColor};
        letter-spacing: 0.3em;
        margin-right: -0.3em;
    }
    &:focus::placeholder {
        color: transparent;
    }
`
const SubmitButton = styled(Button)`
    margin-top: 15px;
    width: 100%;
    max-width: 300px;
    height: 40px;
    border-radius: 20px;
    background: linear-gradient(90deg, ${branding.loginRegistrationSite.loginBackgroundColor1} 0%, ${branding.loginRegistrationSite.loginBackgroundColor2} 100%);
    font-family: ${branding.font2};;
    font-size: 16px;
    letter-spacing: 0.3em;
    margin-right: -0.3em;

    &.btn:disabled {
        opacity: .3;
    }

    &:active {
        background: linear-gradient(90deg, ${branding.loginRegistrationSite.loginBackgroundColor1} 0%, ${branding.loginRegistrationSite.loginBackgroundColor2} 100%);
    }
`

const ChangeEmailButton = styled(Button)`
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
    color: ${branding.loginRegistrationSite.changeEmailAddressLinkColor ?? "#fff"};
`

const EmailPanel = styled.div`
    margin-bottom: 5px;
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    text-align: center;
`

const NoTokenPanel = styled.div`
    margin-top: 30px;
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    text-align: center;
`

const NewTokenButton = styled(Button)`
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    font-size: 14px;
`

const CreateNewTokenButton = styled(Button)`
    padding-left: 0px;
    padding-bottom: 0px;
`

const TicketPanel = styled.div`
    margin-top: 50px;
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    text-align: center;
`

const DefaultTicketButton = React.memo((props) => {
    const strings = useLanguageState().getStrings();
    return <DefaultTicketButtonPanel onClick={() => window.open(`${branding.loginRegistrationSite.getTicketsLink}`, "_blank")}>{strings.loginRegistrationSite.buyTicketButton}</DefaultTicketButtonPanel>
})

const DefaultTicketButtonPanel = styled.div`
    margin-top: 6px;
    & {
        text-align: center;
    }
    & button {
        color: #1675e0;
        background-color: #ffffff00;
    }
    & button:hover {
        text-decoration: underline;
    }
    & button:focus {
        outline: none;
    }
`

const DataPrivacyPanel = styled.div`
    margin-top: 15px;
    width: 100%;
    max-width: 400px;
    min-width: 200px;
    text-align: center;
    font-size: small;
`


const ErrorMessagesContainer = styled.div`
    margin-top: 2rem;
    font-size: inherit;

    & .invalid-email-link{
        color: ${branding.loginRegistrationSite.invalidEmailPanelWithLink.linkPart.textColor};  
    }
`

// Center login style

export const CenterLoginStyleRoot = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-image: url("/branding/login-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 1.1rem;
`

export const CenterLoginStyleOverlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${branding.loginRegistrationSite.centerLoginStyleOverlay};
`

const CenterLoginStyleHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 3rem;
    position: relative;
    z-index: 10;

    & img {
        max-width: ${branding.loginRegistrationSite.centerLoginLogoMaxWidth ?? "150px"};
        max-height: ${branding.loginRegistrationSite.centerLoginLogoMaxHeight ?? "50px"};
        object-fit: contain;
        user-select: none;
    }
`

const CenterLoginStyleTicketBtn = styled(Button)`
    font-size: inherit;
    border-radius: 40px;
    border: ${branding.loginRegistrationSite.centerLoginStyleTicketBorderBtn};
    background-color: transparent;
    color: ${branding.loginRegistrationSite.centerLoginStyleTicketTextColorBtn};
    padding: .5rem 2rem;
    
    &:hover {
        background-color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnOnHoverBgColor};
        color: ${branding.loginRegistrationSite.centerLoginStyleTicketBtnOnHoverTextColor};
    }
`

const CenterLoginStyleTicketButton = React.memo((props) => {
    const strings = useLanguageState().getStrings();
    return <CenterLoginStyleTicketBtn onClick={() => window.open(`${branding.loginRegistrationSite.getTicketsLink}`, "_blank")}>{strings.loginRegistrationSite.buyTicketButton}</CenterLoginStyleTicketBtn>
})

const CenterLoginStyleInputContainer = styled.div<{ windowHeight?: number }>`
    width: 600px;
    padding: ${props => props.windowHeight && props.windowHeight < 1200 ? "2rem" : "3.5rem"}; 
    font-size: inherit;
    border: 2px solid #fff;
    position: absolute;
    top: ${props => props.windowHeight && props.windowHeight < 1200 ? "55%" : "50%"};
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${branding.loginRegistrationSite.loginModalTextColor ?? "#fff"};
    font-family: ${branding.font1};

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${branding.loginRegistrationSite.loginBoxOverlayColor ?? "rgba(0,0,0,.5)"};
        backdrop-filter: blur(3px);
        z-index: -1;
    }
    &.loading::before {
        z-index: 2;
    }

    
    @media only screen and (max-width: 640px) {
      width: 90%;
    }
`

const CenterLoginStyleInputLabel = styled.div`
    font-size: .9rem;
    margin-top: 1.5rem;
    &.error {
        color: ${branding.dangerButtonColor};
    }
`
const CenterLoginStyleInput = styled(Form.Control)`
    height: 40px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${branding.loginRegistrationSite.loginInputMailTextColor ?? "#fff"};
    background-color: transparent;
    color: ${branding.loginRegistrationSite.loginInputMailTextColor ?? "#fff"};
    font-size: inherit;
    padding: 0;
    

    &:focus, &:hover {
        border-bottom: 1px solid ${branding.loginRegistrationSite.loginInputMailTextColor ?? "#fff"};
        background-color: transparent;
        box-shadow: none;
        color: ${branding.loginRegistrationSite.loginInputMailTextColor ?? "#fff"};
    }
    &::placeholder {
        color: ${branding.loginRegistrationSite.loginInputMailPlaceholderColor ?? "rgba(255,255,255,.85)"};
    }
    &:focus::placeholder {
        color: transparent;
    }
    &.form-control:-webkit-autofill, &.form-control:-webkit-autofill:hover, &.form-control:-webkit-autofill:focus, &.form-control:-webkit-autofill:active {
        -webkit-text-fill-color: ${branding.loginRegistrationSite.loginInputMailTextColor ?? "#fff"} !important;
        font-size: 1.1rem !important;
    }
`

const CenterLoginStyleErrorMessage = styled.div`
    color: ${branding.dangerButtonColor};;
`
const CenterLoginStyleInfoMessage = styled.div`
    color: #4caf50;
`
const CenterLoginStyleSubmitButton = styled(Button)`
    display: block;
    width: 60%;
    margin: 2rem auto;
    border-radius: 20px;
    text-align: center;
    font-size: inherit;
    color: #000;

    &.btn:disabled {
        color: #c5c6c7 !important;
        opacity: 1;
    }
`

const CenterLoginStyleForgotPassword = styled.div`
    text-align: center;
    font-size: .9rem;
    padding: 1rem;
    color: ${branding.loginRegistrationSite.changeEmailAddressLinkColor ?? "#fff"};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const CenterLoginStyleHeader = styled.div<{ windowHeight: number }>`
    font-family: ${branding.font2};
    position: absolute; 
    top: ${props => props.windowHeight < 1000 ? "5%" : "10%"}; 
    color: ${branding.secondaryTextColor ?? "#fff"};
    display: flex; 
    justify-content: center;
    flex-direction: column; 
    align-items: center; 
`

const CenterLoginStyleHeaderTitle = styled.h1`
    font-size: 3vw;
    white-space: pre;
    color: ${branding.loginRegistrationSite.loginTitleTextColor ?? "#fff"};
`

const CenterLoginStyleHeaderSubitle = styled.p`
    font-size: 1.5vw;
    white-space: pre;
    color: ${branding.loginRegistrationSite.loginSubTitleTextColor ?? "#fff"};
`

const CenterLoginStyleTicketLink = styled.a`
    font-family: ${branding.font1}; 
    color: ${branding.loginRegistrationSite.loginModalSubtitleTextColor ?? "#fff"};
    cursor: pointer;

    &:active, &:link, &:focus {
        color: ${branding.loginRegistrationSite.loginModalSubtitleTextColor ?? "#fff"};
        text-decoration: none;
    }

    &:hover {
        color: ${branding.loginRegistrationSite.loginModalSubtitleTextColor ?? "#fff"};
        text-decoration: underline;
    }
`

const CenterLoginStyleLoaderAttributes: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3
}

const SystemCheckContainer = styled.div<{ windowHeight?: number }>`
    padding-top: ${props => props.windowHeight ? props.windowHeight < 800 ? "40rem" : props.windowHeight < 1000 ? "48rem" : "50rem" : "45rem"};
    font-family: ${branding.font1};

    .btn:focus, .btn:active {
        outline: none !important;
        box-shadow: none !important;
    }

    .btn-dark {
        font-size: .9rem;
        border-radius: 2rem;
        background-color: ${branding.loginRegistrationSite.centerLoginSystemCheckBackgroundColor ?? "rgba(0,0,0,.5)"};
    }
`;


export enum ProfileResponseStatus {
    NONE,
    SUCCESS,
    FAILURE_EMAIL_INVALID,
    FAILURE_PRIVACY_DOCS_OUTDATED,
    FAILURE,
    WRONG_PASSWORD,
    TOKEN_INVALIDATED,
    WRONG_TOKEN,
    THIRD_PARTY_USER_NOT_FOUND
}

enum LoginLayoutType {
    HorizontalSplitLoginRightStyle = 'HorizontalSplitLoginRightStyle', // default one 
    CenterLoginStyle = 'CenterLoginStyle'
}

interface LoginRegistrationSiteProps {
    dataPrivacyDoc: DataPrivacyDoc | undefined,
    loadingStatus: AppLoadingStatus
}

const LoginRegistrationSite: React.FC<LoginRegistrationSiteProps> = (props) => {
    const userLink = useLoggedInState()
    const favorites = useFavoriteState()
    const loginAbortController = new AbortController();
    const key = userLink.user() === undefined ? "user" : ""
    const directionClass = userLink.user() === undefined ? "dir-east" : "dir-west"
    const fullWidthClassName = props.loadingStatus === AppLoadingStatus.LOADING ? "fullWidth" : ""

    useEffect(() => {

        if (branding.gtmScriptDataLayer && (window as any).dataLayer && (window as any).dataLayer[0]) {
            (window as any).dataLayer.push({
                loggedIn: "Ausgeloggt"
            });
        }
        return () => {
            loginAbortController.abort();
        }
        // eslint-disable-next-line
    }, [])

    const syncFavorites = (userId: string) => {
        syncFavoritesBackend({
            profileId: userId,
            body: {
                currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // "2020-01-01 12:00:00",
                lastSyncTime: favorites.getLastSyncTime()
            }
        }).then((resp) => {
            const temp: Array<any> = resp.content.favorites;
            favorites.setLastSyncTime(new Date())
            temp.forEach(item => {
                if (!item.deleted && !favorites.is("person", item.id)) {
                    favorites.add("person", item.id)
                }
                if (!item.deleted && !favorites.is("organization", item.id)) {
                    favorites.add("organization", item.id)
                }
                if (!item.deleted && !favorites.is("eventdate", item.id)) {
                    favorites.add("eventdate", item.id)
                }
                if (!item.deleted && !favorites.is("product", item.id)) {
                    favorites.add("product", item.id)
                }
                if (!item.deleted && !favorites.is("trademark", item.id)) {
                    favorites.add("trademark", item.id)
                }
                if (!item.deleted && !favorites.is("sotuser", item.id)) {
                    favorites.add("sotuser", item.id) 
                }
            });
        }).catch((e: { message: React.SetStateAction<string> }) => {
            // syncFavorites failed, logged in BackendServices
        })
    }

    const createAppSyncUser = async (user: User) => {
        const appSyncUser = await getUserById(user.profileId)
        const userName = [user!.firstName, user!.middleName, user!.lastName].filter(Boolean).join(" ")
        const startPresenceType = PresenceType.AVAILABLE
        if ((appSyncUser as UserResponse)?.getUser) {
            await updateUserValues({ id: user!.profileId, name: userName, pictureUrl: user!.logoUrl, presenceStatus: startPresenceType, lastConnected: new Date().toISOString() })
        } else {
            await createNewUser({ id: user!.profileId, name: userName, pictureUrl: user!.logoUrl, presenceStatus: startPresenceType, lastConnected: new Date().toISOString() })
            await createUnreadCounterUser({ id: user!.profileId, requests: 0, contacts: 0, conversations: 0, schedules: 0 })
        }
    }

    const registerWithPassword = (email: string, password: string): Promise<ProfileResponseStatus> => {
        return (async () => {
            if (!props.dataPrivacyDoc) {
                return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
            }

            const resp = await loginWithPassword(email, password, { dataPrivacyDocs: [props.dataPrivacyDoc] })
            const error = (resp as BackendServiceError);
            if (error.httpStatus) {
                const json = error.responseJson;
                if (json?.errorCode === "thirdPartyUserNotFound") {
                    return ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND
                }
                if (json?.emailInvalid)
                    return ProfileResponseStatus.FAILURE_EMAIL_INVALID
                if (json?.dataPrivacyDocsOutdated)
                    return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
                if (error.httpStatus === 401)
                    return ProfileResponseStatus.WRONG_PASSWORD

                return ProfileResponseStatus.FAILURE
            } else {
                const profileResp = resp as ProfileResponse
                try {
                    await cognitoLogin(profileResp.profile.profileId, profileResp.beConnectionToken, email)
                } catch (error) {
                    // should never happen because we have a valid beConnectionToken here
                    logger.error({ message: "LoginRegistrationSite cognito login failed", errorMessage: error.message, errorStack: error.stack })
                    return ProfileResponseStatus.FAILURE
                }
                userLink.setUserState({ jwtToken: profileResp.beConnectionToken, user: profileResp.profile })
                syncFavorites(profileResp.profile.profileId)
                await createAppSyncUser(profileResp.profile)
                return ProfileResponseStatus.SUCCESS
            }
        })()
    }

    const register = (email: string): Promise<ProfileResponseStatus> => {
        return (async () => {
            if (!props.dataPrivacyDoc) {
                return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED
            }

            const resp = await registerProfile(email, { dataPrivacyDocs: [props.dataPrivacyDoc] })
            const error = (resp as BackendServiceError);
            if (error.httpStatus) {
                const json = error.responseJson;
                if (json?.errorCode === "thirdPartyUserNotFound") {
                    return ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND
                }
                if (json?.emailInvalid)
                    return ProfileResponseStatus.FAILURE_EMAIL_INVALID
                if (json?.dataPrivacyDocsOutdated)
                    return ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED

                return ProfileResponseStatus.FAILURE
            } else {
                const profileIdResp = resp as { profileId: string }
                userLink.setUser({ email: email, profileId: profileIdResp.profileId })
                return ProfileResponseStatus.SUCCESS
            }
        })()
    }

    const connect = (token: string): Promise<ProfileResponseStatus> => {
        return (async () => {
            const profileId = userLink.user()?.profileId
            if (profileId === undefined) {
                return ProfileResponseStatus.FAILURE
            }

            const resp = await connectProfile(profileId, token)
            if ((resp as BackendServiceError).httpStatus) {
                const errorResp = resp as BackendServiceError
                // Bad Request, token was input wrong > 3, registerProfile has to be called again to prepare the profile for connection and create a new token
                if (+errorResp.httpStatus === 400) {
                    return ProfileResponseStatus.TOKEN_INVALIDATED
                }
                return ProfileResponseStatus.WRONG_TOKEN
            } else {
                const profileResp = resp as ProfileResponse
                try {
                    await cognitoLogin(profileId, profileResp.beConnectionToken, profileResp.profile.email)
                } catch (error) {
                    // should never happen because we have a valid beConnectionToken here
                    logger.error({ message: "LoginRegistrationSite ", errorMessage: error.message, errorStack: error.stack })
                    return ProfileResponseStatus.FAILURE
                }
                userLink.setUserState({ jwtToken: profileResp.beConnectionToken, user: profileResp.profile })
                syncFavorites(profileResp.profile.profileId)
                await createAppSyncUser(profileResp.profile)
                return ProfileResponseStatus.SUCCESS
            }
        })()
    }

    const functionPanel = props.loadingStatus === AppLoadingStatus.SUCCESS ?
        <TransitionGroup>
            <CSSTransition
                key={key}
                classNames={loginStepTransitionName}
                timeout={animationDuration}
                onExit={() => { loginAbortController.abort() }} >
                <AnimatedLoginStepItem className={directionClass}>
                    <LoginRegistrationFunctionContainer>
                        <EventLogo src="./branding/logo.png" />
                        <InputDisplay abortSignal={loginAbortController.signal} registerProfile={register} registerWithPassword={registerWithPassword} connectProfile={connect} email={userLink.user()?.email} setUser={userLink.setUser} dataPrivacyDoc={props.dataPrivacyDoc} />
                    </LoginRegistrationFunctionContainer>
                </AnimatedLoginStepItem>
            </CSSTransition>
        </TransitionGroup>
        : <ErrorSite />

    const CenterLoginStyleLayout = props.loadingStatus === AppLoadingStatus.SUCCESS ?
        <CenterLoginStyleRoot>
            <CenterLoginStyleOverlay>
                <CenterLoginStyleHeaderContainer>
                    <img src="./branding/logo.png" alt="Logo" />
                    <div>
                        <TicketDisplay ticketBtnType="centerLogin" />
                        <LanguageToggler color={branding.languageTogglerColor} />
                    </div>
                </CenterLoginStyleHeaderContainer>
                <TransitionGroup>
                    <CSSTransition
                        key={key}
                        classNames={loginStepTransitionName}
                        timeout={animationDuration}
                        onExit={() => { loginAbortController.abort() }} >
                        <AnimatedLoginStepItem className={directionClass}>
                            <InputDisplay abortSignal={loginAbortController.signal} loginLayoutType={LoginLayoutType.CenterLoginStyle} registerProfile={register} registerWithPassword={registerWithPassword} connectProfile={connect} email={userLink.user()?.email} setUser={userLink.setUser} dataPrivacyDoc={props.dataPrivacyDoc} />
                        </AnimatedLoginStepItem>
                    </CSSTransition>
                </TransitionGroup>
            </CenterLoginStyleOverlay>
        </CenterLoginStyleRoot>
        : null

    const DefaultLoginLayout = () => {
        const strings = useLanguageState().getStrings();
        return (
            <div>
                <LanguageSelector />
                <LoginRegistrationStylePanel className={fullWidthClassName}>
                    <LoginRegistrationStylePanelTitle>{strings.loginRegistrationSite.loginRegistrationTitle}</LoginRegistrationStylePanelTitle>
                    <LoginRegistrationStylePanelSubTitle>{strings.loginRegistrationSite.loginRegistrationSubtitle}</LoginRegistrationStylePanelSubTitle>
                    <LoginRegistrationStylePanelBackground0 />
                    <LoginRegistrationStylePanelBackground1 />
                    <LoginRegistrationStylePanelBackground2 />
                </LoginRegistrationStylePanel>
                <LoginRegistrationFunctionPanel>
                    {functionPanel}
                </LoginRegistrationFunctionPanel>
            </div>
        )
    }

    const LoginLayout = () => {
        if (branding.loginRegistrationSite.loginPageType === LoginLayoutType.CenterLoginStyle)
            return CenterLoginStyleLayout
        else
            return DefaultLoginLayout()
    }

    return (
        <LoginRegistrationSiteRoot>
            {LoginLayout()}
            {
                branding.loginRegistrationSite.showImprintAndPrivacyPolicyInFooter &&
                <PageFooter />
            }
        </LoginRegistrationSiteRoot>
    )
}

const PageFooterRoot = styled.div`
  position: fixed;
  color: #fff;
  height: 50px;
  width: 100%;
  margin-left: auto;
  right: 0;
  bottom: 0;
`

export const PageFooter: React.FunctionComponent = () => {

    const strings = useLanguageState().getStrings()

    return <PageFooterRoot>
        <div style={{ marginTop: "15px", marginLeft: "80%", width: "15%", fontSize: "16px", fontFamily: branding.font1 }}>
            <div style={{ float: "left" }}>
                <a href={strings.loginRegistrationSite.privacyPolicyLinkUrl} style={{ color: "#fff" }} target="_blank" rel="noopener noreferrer">
                    {strings.loginRegistrationSite.privacyPolicyLink}
                </a>
            </div>

            <div style={{ float: "right" }}>
                <a href={strings.loginRegistrationSite.imprintLinkUrl} style={{ color: "#fff" }} target="_blank" rel="noopener noreferrer">
                    {strings.loginRegistrationSite.imprintLink}
                </a>
            </div>
        </div>
    </PageFooterRoot >
}

enum TokenMessagePanelType {
    NONE,
    ERROR_TOKEN_INVALID,
    ERROR_TOKEN_INVALIDATED,
    ERROR_CREATE_TOKEN_FAILED,
    SUCCESS_CREATE_TOKEN,
}

interface InputDisplayProps {
    registerProfile: (email: string) => Promise<ProfileResponseStatus>,
    registerWithPassword: (email: string, password: string) => Promise<ProfileResponseStatus>,
    connectProfile: (token: string) => Promise<ProfileResponseStatus>,
    email?: string,
    setUser: (user?: User) => void,
    dataPrivacyDoc: DataPrivacyDoc | undefined,
    loginLayoutType?: LoginLayoutType
    abortSignal: AbortSignal
}

function InputDisplay(props: InputDisplayProps) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [token, setToken] = useState("")
    const [registerResponse, setRegisterResponse] = useState({ status: ProfileResponseStatus.NONE })
    const [registerWithPasswordResponse, setRegisterWithPasswordResponse] = useState({ status: ProfileResponseStatus.NONE })
    const [connectResponse, setConnectResponse] = useState({ status: ProfileResponseStatus.NONE })
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const [isLoaded, setIsLoaded] = useState(true);
    const history = useHistory();
    const windowSize = useWindowDimensions();

    const userEmail = props.email
    const loginWithPassword = branding.loginRegistrationSite.loginWithPassword

    function onSubmitButtonClicked() {
        if (submitDisabled) return;

        setIsLoaded(false)
        if (loginWithPassword) {
            setSubmitDisabled(true)
            const registerWithPassword = props.registerWithPassword(email, password)
            registerWithPassword.then(resp => {
                if (!props.abortSignal.aborted) {
                    setSubmitDisabled(false)
                    setIsLoaded(true)
                    setRegisterWithPasswordResponse({ status: resp })
                }
                localStorage.removeItem("logoutReason")
            })
        } else {
            setSubmitDisabled(true)
            const registerResponse = props.registerProfile(email)
            registerResponse.then(resp => {
                if (!props.abortSignal.aborted) {
                    setSubmitDisabled(false)
                    setIsLoaded(true)
                    setRegisterResponse({ status: resp })
                }
                localStorage.removeItem("logoutReason")
            })
        }
    }

    function onEmailChanged(enteredEmail: string, e: any) {
        setEmail(enteredEmail)
        onPasswordOrEmailChanged(enteredEmail, password)
    }

    function onPasswordChanged(enteredPassword: string, e: any) {
        setPassword(enteredPassword)
        onPasswordOrEmailChanged(email, enteredPassword)
    }

    function onPasswordOrEmailChanged(email: string, password: string) {
        const emailRegex = new RegExp('^.+@.+\\.[A-Za-z]{2,20}$')
        const disabled = !emailRegex.test(email) || (loginWithPassword && password.length === 0)
        setSubmitDisabled(disabled)
        setConnectResponse({ status: ProfileResponseStatus.NONE })
        setRegisterResponse({ status: ProfileResponseStatus.NONE })
        setRegisterWithPasswordResponse({ status: ProfileResponseStatus.NONE })
    }

    function onTokenChanged(enteredToken: string, e: any) {
        setToken(enteredToken)
        setConnectResponse({ status: ProfileResponseStatus.NONE })
        setRegisterResponse({ status: ProfileResponseStatus.NONE })
    }

    function onTokenSubmitButtonClicked() {
        const connectRepsonse = props.connectProfile(token)
        connectRepsonse.then(cr => setConnectResponse({ status: cr }))
        setToken("")
    }

    function onCreateNewTokenButtonClicked() {
        if (!userEmail) return
        const resp = props.registerProfile(userEmail)
        resp.then(rr => setRegisterResponse({ status: rr }))

        setConnectResponse({ status: ProfileResponseStatus.NONE })
        setToken("")
    }

    function onChangeEmailButtonClicked() {
        props.setUser(undefined)
        setToken("")
    }

    const tokenInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let password = params.get(branding.loginRegistrationSite.passwordQueryParam);
        let email = params.get(branding.loginRegistrationSite.emailQueryParam);
        if (password && email) {
            params.delete(branding.loginRegistrationSite.passwordQueryParam)
            params.delete(branding.loginRegistrationSite.emailQueryParam)
            history.replace({
                search: params.toString(),
            })
            setIsLoaded(false)
            props.registerWithPassword(email, password).then(resp => {
                if (!props.abortSignal.aborted) {
                    setIsLoaded(true)
                    setRegisterWithPasswordResponse({ status: resp })
                }
                localStorage.removeItem("logoutReason")
            })
        }
        tokenInputRef.current?.focus()

        // return () => {
        //     params.delete(branding.loginRegistrationSite.passwordQueryParam)
        //     params.delete(branding.loginRegistrationSite.emailQueryParam)
        //     history.replace({
        //         search: params.toString(),
        //       })
        // }
    }, []) // eslint-disable-line

    function passwordForgotClick() {
        window.open(strings.loginRegistrationSite.passwordForgottenUrl)
    }

    function termsOfUseClick() {
        window.open(strings.loginRegistrationSite.termsOfUseUrl)
    }

    const RenderErrorMessages = (localLogoutReason: string | null) => {
        const invalidEmail = branding.loginRegistrationSite.invalidEmailPanelWithLink.visible ?
            <p>{strings.loginRegistrationSite.invalidEmailPanelWithLink.befLinkPart}
                <a className="invalid-email-link" href={strings.loginRegistrationSite.invalidEmailPanelWithLink.linkPart.link} target="_blank" rel="noopener noreferrer">
                    {strings.loginRegistrationSite.invalidEmailPanelWithLink.linkPart.text}
                </a>
                {strings.loginRegistrationSite.invalidEmailPanelWithLink.aftLinkPart}
            </p>
            :
            <p>{strings.loginRegistrationSite.invalidEmailPanel}</p>


        if (props.loginLayoutType === LoginLayoutType.CenterLoginStyle) {
            return (
                <ErrorMessagesContainer>
                    <CenterLoginStyleErrorMessage>
                        {(localLogoutReason)
                            && <p>{localLogoutReason}</p>}

                        {(registerResponse.status === ProfileResponseStatus.FAILURE || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE)
                            && <p>{strings.loginRegistrationSite.registerEmailFailed}</p>}

                        {(registerResponse.status === ProfileResponseStatus.FAILURE_EMAIL_INVALID || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE_EMAIL_INVALID)
                            && invalidEmail}

                        {(registerResponse.status === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED)
                            && <p>{strings.loginRegistrationSite.outdatedPrivacyDocsPanel}</p>}

                        {(registerResponse.status === ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND || registerWithPasswordResponse.status === ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND)
                            && <p>{strings.loginRegistrationSite.thirdPartyUserNotFound}</p>}

                        {(registerWithPasswordResponse.status === ProfileResponseStatus.WRONG_PASSWORD)
                            && <p>{strings.loginRegistrationSite.wrongPasswordPanel}</p>}

                    </CenterLoginStyleErrorMessage>
                </ErrorMessagesContainer>
            )
        } else {
            return (
                <ErrorMessagesContainer>
                    <MessagePanel
                        show={localLogoutReason !== null}
                        type="danger"
                        description={<p>{localLogoutReason}</p>}
                        showIcon />
                    <MessagePanel
                        show={registerResponse.status === ProfileResponseStatus.FAILURE || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE}
                        type="danger"
                        description={<p>{strings.loginRegistrationSite.registerEmailFailed}</p>}
                        showIcon />
                    <MessagePanel
                        show={registerResponse.status === ProfileResponseStatus.FAILURE_EMAIL_INVALID || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE_EMAIL_INVALID}
                        type="danger"
                        description={invalidEmail}
                        showIcon />
                    <MessagePanel
                        show={registerResponse.status === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED}
                        type="danger"
                        description={<p>{strings.loginRegistrationSite.outdatedPrivacyDocsPanel}</p>}
                        showIcon />
                    <MessagePanel
                        show={registerResponse.status === ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND || registerWithPasswordResponse.status === ProfileResponseStatus.THIRD_PARTY_USER_NOT_FOUND}
                        type="danger"
                        description={<p>{strings.loginRegistrationSite.thirdPartyUserNotFound}</p>}
                        showIcon />
                    <MessagePanel
                        show={registerWithPasswordResponse.status === ProfileResponseStatus.WRONG_PASSWORD}
                        type="danger"
                        description={<p>{strings.loginRegistrationSite.wrongPasswordPanel}</p>}
                        showIcon />
                </ErrorMessagesContainer >
            )
        }
    }


    const RenderCenterLoginStyleTokenLogin = () => {
        const messageType = calcTokenMessagePanelType(registerResponse.status, connectResponse.status)
        return (
            <CenterLoginStyleInputContainer>
                <div>
                    <p><p>{strings.loginRegistrationSite.centerLoginStyleTitleCode}</p></p>
                    <CenterLoginStyleInputLabel>{strings.loginRegistrationSite.emailPlaceHolder}</CenterLoginStyleInputLabel>
                    <p>{userEmail}</p>
                    <CenterLoginStyleInputLabel>{strings.loginRegistrationSite.tokenPlaceholder}</CenterLoginStyleInputLabel>
                    <CenterLoginStyleInput
                        placeholder={strings.loginRegistrationSite.enterToken} value={token}
                        inputRef={tokenInputRef}
                        onChange={(event: { target: { value: string } }) => onTokenChanged(event.target.value, event)}
                        onKeyPress={(e: KeyboardEvent) => {
                            if (e.key === "Enter") {
                                onTokenSubmitButtonClicked()
                            }
                        }}
                        autoFocus />

                    <CenterLoginStyleSubmitButton
                        variant={'light'}
                        onClick={onTokenSubmitButtonClicked}
                        disabled={token.length === 0}>{strings.loginRegistrationSite.submitTokenButton}</CenterLoginStyleSubmitButton>
                    <TokenMessageDisplay loginLayoutType={LoginLayoutType.CenterLoginStyle} type={messageType} clickListener={onCreateNewTokenButtonClicked} />

                    <CenterLoginStyleForgotPassword style={{ marginTop: '0.5rem' }} onClick={onChangeEmailButtonClicked}>{strings.loginRegistrationSite.changeEmailButton}</CenterLoginStyleForgotPassword>

                    <NoTokenPanel style={{ maxWidth: '100%' }}>{strings.loginRegistrationSite.noToken}</NoTokenPanel>
                    <CenterLoginStyleForgotPassword style={{ marginTop: '0.5rem' }} onClick={onCreateNewTokenButtonClicked}>{strings.loginRegistrationSite.resendToken}</CenterLoginStyleForgotPassword>

                </div>
            </CenterLoginStyleInputContainer>

        )
    }

    const RenderCenterLoginStyleEmailPwLogin = () => {
        if (connectResponse.status === ProfileResponseStatus.TOKEN_INVALIDATED || connectResponse.status === ProfileResponseStatus.FAILURE || connectResponse.status === ProfileResponseStatus.WRONG_TOKEN) {
            setConnectResponse({ status: ProfileResponseStatus.NONE })
        }
        return (
            <>
                <CenterLoginStyleInputContainer className={!isLoaded ? 'loading' : ''} windowHeight={windowSize.height}>
                    {!isLoaded && <CenteredLoader loaderWrapperStyle={CenterLoginStyleLoaderAttributes}></CenteredLoader>}
                    <p>{strings.loginRegistrationSite.centerLoginStyleTitle}</p><br />
                    <CenterLoginStyleTicketLink href={strings.loginRegistrationSite.centerLoginStyleTicketLink} target="_blank" rel="noopener noreferrer">{strings.loginRegistrationSite.centerLoginStyleSubtitle}</CenterLoginStyleTicketLink>
                    <CenterLoginStyleInputLabel
                        className={(registerResponse.status === ProfileResponseStatus.FAILURE_EMAIL_INVALID || registerWithPasswordResponse.status === ProfileResponseStatus.FAILURE_EMAIL_INVALID) ? 'error' : ''}>{strings.loginRegistrationSite.emailPlaceHolder}</CenterLoginStyleInputLabel>
                    <CenterLoginStyleInput
                        placeholder={strings.loginRegistrationSite.enterEmail}
                        value={email}
                        onChange={(event: { target: { value: string } }) => onEmailChanged(event.target.value, event)}
                        onKeyPress={(e: KeyboardEvent) => {
                            if (e.key === "Enter") {
                                onSubmitButtonClicked()
                            }
                        }}
                        autoFocus />

                    {branding.loginRegistrationSite.loginWithPassword
                        && <CenterLoginStyleInputLabel
                            className={(registerWithPasswordResponse.status === ProfileResponseStatus.WRONG_PASSWORD) ? 'error' : ''}>{strings.loginRegistrationSite.passwordPlaceHolder}</CenterLoginStyleInputLabel>}
                    <CenterLoginStylePasswordInput
                        onPasswordChanged={onPasswordChanged}
                        onSubmitButtonClicked={onSubmitButtonClicked}
                        password={password}
                        passwordPlaceHolder={strings.loginRegistrationSite.enterPassword} />

                    {RenderErrorMessages(localStorage.getItem("logoutReason") === null ? null : (localStorage.getItem("logoutReason") === "logoutReasonSessionTimeout" ? strings.loginRegistrationSite.logoutReasonSessionTimeout : strings.loginRegistrationSite.logoutReasonForbidden))}

                    <CenterLoginStyleSubmitButton
                        variant={'light'}
                        onClick={onSubmitButtonClicked}
                        disabled={submitDisabled}>{strings.loginRegistrationSite.submitTokenButton}</CenterLoginStyleSubmitButton>


                    {branding.loginRegistrationSite.loginWithPassword
                        && <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <CenterLoginStyleForgotPassword onClick={() => passwordForgotClick()}>{strings.loginRegistrationSite.forgotPassword}</CenterLoginStyleForgotPassword>
                            <CenterLoginStyleForgotPassword onClick={() => termsOfUseClick()}>{strings.loginRegistrationSite.termsOfUseText}</CenterLoginStyleForgotPassword>
                        </div>
                    }
                    {/* {!branding.loginRegistrationSite.loginWithPassword &&
                        <CenterLoginStyleForgotPassword onClick={() => passwordForgotClick()}>{strings.loginRegistrationSite.forgotPassword}</CenterLoginStyleForgotPassword>
                    } */}

                </CenterLoginStyleInputContainer>
                <SystemCheckContainer windowHeight={windowSize.height}>
                    <button className="btn btn-dark px-5 py-2 border-light" onClick={() => history.push("/check")}>
                        {strings.ticketSale.checkYourSystemButton}
                    </button>
                </SystemCheckContainer>
            </>
        )
    }

    const RenderDefaultTokenLogin = () => {
        // const messageType = TokenMessagePanelType.ERROR_TOKEN_INVALID//token.length === 0 ? TokenMessagePanelType.ERROR_TOKEN_INVALID : TokenMessagePanelType.NONE
        const messageType = calcTokenMessagePanelType(registerResponse.status, connectResponse.status)
        return (
            <LoginRegistrationInputPanel>
                <p><p>{strings.loginRegistrationSite.centerLoginStyleTitle}</p></p>
                <EmailPanel>{userEmail}</EmailPanel>
                <LoginInput placeholder={strings.loginRegistrationSite.tokenPlaceholder} value={token}
                    inputRef={tokenInputRef}
                    onChange={(event: { target: { value: string } }) => onTokenChanged(event.target.value, event)}
                    onKeyPress={(e: KeyboardEvent) => {
                        if (e.key === "Enter") {
                            onTokenSubmitButtonClicked()
                        }
                    }}
                    autoFocus />
                <SubmitButton disabled={token.length === 0} variant="primary" onClick={onTokenSubmitButtonClicked}>{strings.loginRegistrationSite.submitTokenButton}</SubmitButton>

                <TokenMessageDisplay type={messageType} clickListener={onCreateNewTokenButtonClicked} />

                <ChangeEmailButton variant="link" onClick={onChangeEmailButtonClicked}>{strings.loginRegistrationSite.changeEmailButton}</ChangeEmailButton>
                <NoTokenPanel>{strings.loginRegistrationSite.noToken}</NoTokenPanel>
                <NewTokenButton variant="link" onClick={onCreateNewTokenButtonClicked}>{strings.loginRegistrationSite.resendToken}</NewTokenButton>
            </LoginRegistrationInputPanel>
        )
    }

    const RenderDefaultEmailPwLogin = () => {
        if (connectResponse.status === ProfileResponseStatus.TOKEN_INVALIDATED || connectResponse.status === ProfileResponseStatus.FAILURE || connectResponse.status === ProfileResponseStatus.WRONG_TOKEN) {
            setConnectResponse({ status: ProfileResponseStatus.NONE })
        }

        return (
            <LoginRegistrationInputPanel>
                <LoginInput style={{ marginTop: 25 }} placeholder={strings.loginRegistrationSite.emailPlaceHolder} value={email}
                    onChange={(event: { target: { value: string } }) => onEmailChanged(event.target.value, event)}
                    onKeyPress={(e: KeyboardEvent) => {
                        if (e.key === "Enter") {
                            onSubmitButtonClicked()
                        }
                    }} />
                <PasswordInput onPasswordChanged={onPasswordChanged} onSubmitButtonClicked={onSubmitButtonClicked} password={password} passwordPlaceHolder={strings.loginRegistrationSite.passwordPlaceHolder} />
                <SubmitButton variant="primary" onClick={onSubmitButtonClicked} disabled={submitDisabled}>{strings.loginRegistrationSite.submitEmailButton}</SubmitButton>
                <DataPrivacyPanel>{<p>{reactStringReplace(strings.loginRegistrationSite.dataPrivacyPanel, "{0}", (match, i) => (<a key={i} href={props.dataPrivacyDoc?.url}>{strings.loginRegistrationSite.dataPolicy}</a>))}</p>}</DataPrivacyPanel>
                {RenderErrorMessages(localStorage.getItem("logoutReason") === null ? null : (localStorage.getItem("logoutReason") === "logoutReasonSessionTimeout" ? strings.loginRegistrationSite.logoutReasonSessionTimeout : strings.loginRegistrationSite.logoutReasonForbidden))}
                <TicketPanel>{strings.loginRegistrationSite.ticketPanel}</TicketPanel>
                <TicketDisplay />
            </LoginRegistrationInputPanel>
        )
    }

    if (props.loginLayoutType === LoginLayoutType.CenterLoginStyle) {
        if (userEmail) {
            if (!branding.loginRegistrationSite.loginWithPassword) {
                return RenderCenterLoginStyleTokenLogin()
            } else {
                return null;
            }
        } else {
            return (
                <>
                    <CenterLoginStyleHeader windowHeight={windowSize.height}>
                        <CenterLoginStyleHeaderTitle className={windowSize.height < 1000 ? "" : "mb-5"}>{strings.loginRegistrationSite.loginRegistrationTitle}</CenterLoginStyleHeaderTitle>
                        <CenterLoginStyleHeaderSubitle>{strings.loginRegistrationSite.loginRegistrationSubtitle}</CenterLoginStyleHeaderSubitle>
                    </CenterLoginStyleHeader>
                    {RenderCenterLoginStyleEmailPwLogin()}
                </>
            )
        }
    } else {
        if (!isLoaded) {
            return <CenteredLoader></CenteredLoader>
        }
        if (userEmail) {
            return RenderDefaultTokenLogin()
        } else {
            return RenderDefaultEmailPwLogin()
        }
    }
}

interface PasswordInputProps {
    onPasswordChanged: (enteredPassword: string, e: any) => void,
    onSubmitButtonClicked: () => void,
    password: string,
    passwordPlaceHolder: string
}

function PasswordInput(props: PasswordInputProps) {
    const loginWithPassword = branding.loginRegistrationSite.loginWithPassword
    if (loginWithPassword) {
        return <LoginInput type={"password"} style={{ marginTop: 5 }} placeholder={props.passwordPlaceHolder} value={props.password}
            onChange={(event: { target: { value: string } }) => props.onPasswordChanged(event.target.value, event)}
            onKeyPress={(e: KeyboardEvent) => {
                if (e.key === "Enter") {
                    props.onSubmitButtonClicked()
                }
            }} />
    } else {
        return null
    }
}

function CenterLoginStylePasswordInput(props: PasswordInputProps) {
    const loginWithPassword = branding.loginRegistrationSite.loginWithPassword
    if (loginWithPassword) {
        return <CenterLoginStyleInput type={"password"} placeholder={props.passwordPlaceHolder} value={props.password}
            onChange={(event: { target: { value: string } }) => props.onPasswordChanged(event.target.value, event)}
            onKeyPress={(e: KeyboardEvent) => {
                if (e.key === "Enter") {
                    props.onSubmitButtonClicked()
                }
            }} />
    } else {
        return null
    }
}

function calcTokenMessagePanelType(registerStatus: ProfileResponseStatus, connectStatus: ProfileResponseStatus) {
    const registerResponseFailed = registerStatus === ProfileResponseStatus.FAILURE ||
        registerStatus === ProfileResponseStatus.FAILURE_EMAIL_INVALID ||
        registerStatus === ProfileResponseStatus.FAILURE_PRIVACY_DOCS_OUTDATED

    if (registerResponseFailed)
        return TokenMessagePanelType.ERROR_CREATE_TOKEN_FAILED

    if (registerStatus === ProfileResponseStatus.SUCCESS)
        return TokenMessagePanelType.SUCCESS_CREATE_TOKEN

    if (connectStatus === ProfileResponseStatus.TOKEN_INVALIDATED) {
        return TokenMessagePanelType.ERROR_TOKEN_INVALIDATED
    } else if (connectStatus === ProfileResponseStatus.WRONG_TOKEN) {
        return TokenMessagePanelType.ERROR_TOKEN_INVALID
    }


    return TokenMessagePanelType.NONE
}

interface TokenMessagePanelProps {
    type: TokenMessagePanelType
    clickListener: () => any
    loginLayoutType?: LoginLayoutType
}

const MessagePanelWrapper = styled.div`
    margin-top: 20px;
`


function TokenMessageDisplay(props: TokenMessagePanelProps) {
    const strings = useLanguageState().getStrings()

    if (props.loginLayoutType === LoginLayoutType.CenterLoginStyle) {
        return <ErrorMessagesContainer>
            <CenterLoginStyleErrorMessage>
                {props.type === TokenMessagePanelType.ERROR_TOKEN_INVALID
                    && <p>{strings.loginRegistrationSite.wrongToken}</p>}

                {props.type === TokenMessagePanelType.ERROR_TOKEN_INVALIDATED
                    && <div>
                        <p>{strings.loginRegistrationSite.wrongToken}</p>
                        <CreateNewTokenButton variant="link" onClick={props.clickListener}>{strings.loginRegistrationSite.createNewToken}</CreateNewTokenButton>
                    </div>}

                {props.type === TokenMessagePanelType.ERROR_CREATE_TOKEN_FAILED
                    && <p>{strings.loginRegistrationSite.createTokenFailed}</p>}
            </CenterLoginStyleErrorMessage>
            <CenterLoginStyleInfoMessage>
                {props.type === TokenMessagePanelType.SUCCESS_CREATE_TOKEN
                    && <p>{strings.loginRegistrationSite.createTokenSuccess}</p>}
            </CenterLoginStyleInfoMessage>
        </ErrorMessagesContainer>
    } else {
        return <MessagePanelWrapper>
            <MessagePanel
                show={props.type === TokenMessagePanelType.ERROR_TOKEN_INVALID}
                description={<p>{strings.loginRegistrationSite.wrongToken}</p>}
                type="danger"
                showIcon />
            <MessagePanel
                show={props.type === TokenMessagePanelType.ERROR_TOKEN_INVALIDATED}
                type="danger"
                description={
                    <div>
                        <p>{strings.loginRegistrationSite.wrongToken}</p>
                        <CreateNewTokenButton variant="link" onClick={props.clickListener}>{strings.loginRegistrationSite.createNewToken}</CreateNewTokenButton>
                    </div>}
                showIcon />
            <MessagePanel
                show={props.type === TokenMessagePanelType.ERROR_CREATE_TOKEN_FAILED}
                type="danger"
                description={<p>{strings.loginRegistrationSite.createTokenFailed}</p>}
                showIcon />
            <MessagePanel
                show={props.type === TokenMessagePanelType.SUCCESS_CREATE_TOKEN}
                description={<p>{strings.loginRegistrationSite.createTokenSuccess}</p>}
                type="success"
                showIcon />
        </MessagePanelWrapper>
    }

}

interface TicketDisplayProps {
    ticketBtnType?: string
}

function TicketDisplay(props: TicketDisplayProps) {
    if (branding.ticketSale.getYourTicketVisible)
        return props.ticketBtnType === 'centerLogin' ? <CenterLoginStyleTicketButton /> : <DefaultTicketButton />
    return null
}


export default LoginRegistrationSite
