import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PresenceType, AvatarType, getPresenceByUserId, UserResponse } from "../backendServices/BackendServices"
import { useUsersPresence } from "../globalStates/ConnectedUsers";
import VizSensor from 'react-visibility-sensor'
import { AvatarWithDefault } from "./AvatarWithDefault";
import { useLoggedInState } from "../globalStates/LoggedInUser";
import branding from "../branding/branding";
import  AvatarDefaultContent  from './AvatarDefaultContent'

export interface presenceStatusProps {
    status: PresenceType
    color: string
}

export interface avatarTypeProps {
    type: AvatarType
    bordercolor: string
}

export interface badgeProps {
    bgcolor: string
    btop: number
    bright: number
    bwidth: number
    bfontsize: number
    bpaddingtop: number
}
export interface badgeWrapperProps {
    bsize: number
}

export interface badgePresenceProps {
    bgcolor: string
    badgesize?: number
    top?: number
    right?: number
}

export const BadgePresenceDefault: presenceStatusProps = {
    status: PresenceType.DEFAULT,
    color: "#B2B2B2",
}

export const BadgeAvailable: presenceStatusProps = {
    status: PresenceType.AVAILABLE,
    color: "#00B300",
    // "#39b82c" old color
}

export const BadgeBusy: presenceStatusProps = {
    status: PresenceType.BUSY,
    color: "#FFAA00",
    // "#ffc966" old color
}

export const BadgeDoNotDisturb: presenceStatusProps = {
    status: PresenceType.DONOTDISTURB,
    color: branding.dangerButtonColor
}

export const BadgeOffwork: presenceStatusProps = {
    status: PresenceType.OFFWORK,
    color: "#B2B2B2",
    // #888888 old color
}

export const BadgeAvatarTypeVisitor: avatarTypeProps = {
    type: AvatarType.VISITOR,
    bordercolor: "#00FFFFFF",
}

export const BadgeAvatarTypeSpeaker: avatarTypeProps = {
    type: AvatarType.SPEAKER,
    bordercolor: branding.dangerButtonColor
}

export const BadgeAvatarTypeExhibitor: avatarTypeProps = {
    type: AvatarType.EXHIBITOR,
    bordercolor: "#262eff", /** red #ff0000*/
}

export function getPresenceState(type: PresenceType): presenceStatusProps {
    switch (type) {
        case PresenceType.DEFAULT:
            return BadgePresenceDefault
        case PresenceType.AVAILABLE:
            return BadgeAvailable
        case PresenceType.BUSY:
            return BadgeBusy
        case PresenceType.DONOTDISTURB:
            return BadgeDoNotDisturb
        case PresenceType.OFFWORK:
            return BadgeOffwork
    }
}




const BadgeWrapperWithStatus = styled.div <badgeWrapperProps> `
    min-width:${props => props.bsize}px;
    width:${props => props.bsize}px;
    height:${props => props.bsize}px;
    border:1px solid;
    border-radius: 50%;
    padding: 2px 0px 0px 2px;

    &.visitor {
        border-color: transparent;
    }

    &.speaker {
        border-color:  ${branding.dangerButtonColor};
    }

    &.exhibitor {
        border-color: #262eff;
    }
`
const StatusContainer = styled.div <badgePresenceProps>`
    min-width:${props => props.badgesize}px;
    width:${props => props.badgesize}px;
    height:${props => props.badgesize}px;
    position: absolute;
    right: ${props => props.right}px;
    top: ${props => props.top}px;
    border: solid 2px white;
    border-radius: 100%;
    /* background-clip: content-box; */
    color: white;
    background-color: ${props => props.bgcolor};
`;

const AvatarContainer = styled.div`
    width: 55px;
    position: relative;
`;

interface TimerMap {
    [key: string]: number | undefined
}
const timerMap: TimerMap = {}

interface PictureUrl {
    pictureUrl: string,
    alt: string
}

export interface AvatarWithPresenceStateProps {
    userId?: string
    userType?: any
    content: any | PictureUrl
    avatarSize: number
    badgeSize?: number
    badgeTop?: number
    badgeLeft?: number
    badgeRight?: number
    showAvatarBadge?: boolean
    initPresenceByList?: boolean
}

export const AvatarWithPresenceState: React.FC<AvatarWithPresenceStateProps> = (props) => {
    const usersPresence = useUsersPresence()
    const curPresence = usersPresence.getPresenceState(props.userId) ? usersPresence.getPresenceState(props.userId) : PresenceType.DEFAULT
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId
    const userId = props.userId
    const initPresenceByList = (props.initPresenceByList === undefined) ? true : props.initPresenceByList

    const [visible, setVisible] = useState(false)
    useEffect(() => {
        const abortController = new AbortController()
        if (userId !== undefined) {
            if (visible) {
                const subscriptionTimer = window.setTimeout(() => {
                    if (!initPresenceByList) {
                        getPresenceByUserId(userId).then(result => {
                            if (!abortController.signal.aborted) {
                                if (result as UserResponse) {
                                    const user = (result as UserResponse)?.getUser
                                    usersPresence.create(userId, userId === loggedInUserId, user?.presenceStatus, user?.lastConnected)
                                } else
                                    usersPresence.create(userId, userId === loggedInUserId)
                            }
                        })
                    }
                    if (branding.configuration.useSubscriptionPresenceState) {
                        usersPresence.subscribe(userId)
                    }
                    clearTimeout(timerMap[userId])
                    timerMap[userId] = undefined
                }, 300 * 1000) // every 5 minutes should be enough
                timerMap[userId] = subscriptionTimer
                return () => {
                    if (timerMap[userId]) {
                        clearTimeout(timerMap[userId])
                        timerMap[userId] = undefined
                    } else {
                        usersPresence.unsubscribe(userId)
                    }
                }
            }
        }
        return () => abortController.abort()

    }, [visible, userId]) //eslint-disable-line

    function visibilityHandler(isVisibility: boolean) {
        setVisible(isVisibility)
    }



    const presenceAttributes = getPresenceState(curPresence)

    const pictureUrlContent = props.content as PictureUrl

    const content: JSX.Element | null = pictureUrlContent.pictureUrl || pictureUrlContent.alt
        ? <AvatarWithDefault size={props.avatarSize - 6} alt={pictureUrlContent.alt} src={pictureUrlContent.pictureUrl} badgesize={(props.badgeSize !== null ? (props.badgeSize) : undefined)} badgeTop={(props.badgeTop !== null ? (props.badgeTop) : undefined)} badgeRight={(props.badgeRight !== null ? (props.badgeRight) : undefined)} badgeLeft={(props.badgeLeft !== null ? (props.badgeLeft) : undefined)} />
        : React.isValidElement(props.content) ? props.content : <AvatarDefaultContent />


        

    return (
        <VizSensor onChange={visibilityHandler}
            partialVisibility={true}>
            <AvatarContainer className="avt-cont">
                <BadgeWrapperWithStatus
                    className={props.userType === "attendee" ? 'visitor' : props.userType === "speaker" ? 'speaker' : props.userType === "staff" ? 'exhibitor' : 'visitor'}
                    bsize={props.avatarSize}
                >{content}
                    {(props.showAvatarBadge && props.badgeSize !== null) && <StatusContainer
                        className="badgeStatus-cont"
                        bgcolor={presenceAttributes ? presenceAttributes.color : "#ffffff"}
                        badgesize={props.badgeSize !== null ? (props.badgeSize) : undefined}
                        top={props.badgeTop !== null ? (props.badgeTop) : undefined}
                        right={props.badgeRight !== null ? (props.badgeRight) : undefined}
                    />}
                </BadgeWrapperWithStatus>
            </AvatarContainer>
        </VizSensor>
    )
}

export default AvatarWithPresenceState
