import { BusinessAreaPageContentBranding } from './../contentArea/BusinessAreaPageContent';
import { PressMediaPageContentBranding } from '../contentArea/pressMedia/PressMediaPageContent';
import { ContextMenuBranding } from './../ui/ContextMenu';
import { MySchedulePageBranding } from './../contentArea/reception/MySchedule';
import { RulesPageContentBranding } from './../contentArea/RulesPageContent';
import { GuestUserBannerBranding, TopBannerConfig } from '../contentArea/guestUserBanner/GuestUserBanner';
import { HelpPageContentBranding } from './../contentArea/HelpPageContent';
import { OrganizationDetailPageContentBranding } from './../contentArea/detailPages/OrganizationDetailPageContent';
import { InfosPageContentBranding } from './../contentArea/InfosPageContent';
import { ExhibitorsPageContentBranding } from './../contentArea/ExhibitorsPageContent';
import { TrademarksPageContentBranding } from './../contentArea/TrademarksPageContent';
import { ProductsPageContentBranding } from './../contentArea/ProductsPageContent';
import { NewsPageContentBranding } from './../contentArea/NewsPageContent';
import { EventDateDetailPageContentBranding } from './../contentArea/detailPages/EventDateDetailPageContent';
import { TrademarkDetailPageContentBranding } from './../contentArea/detailPages/TrademarkDetailPageContent';
import { ProductDetailPageContentBranding } from './../contentArea/detailPages/ProductDetailPageContent';
import { SayHelloModalBranding } from './../ui/SayHelloModal';
import { FilterBarBranding } from '../ui/FilterBar';
import { PersonDetailPageContentBranding } from './../contentArea/detailPages/PersonDetailPageContent';
import { MyProfilePageContentBranding } from '../contentArea/myprofile/MyProfilePageContent';
import { NavigationAreaBranding } from '../navigationArea/NavigationAreaBranding';
import { ReceptionPageSiteBranding } from '../contentArea/reception/ReceptionPageContent';
import { GlobalStatePopupTextsBranding } from './../globalStates/GlobalStatePopupTextsBranding'
import { LoginRegistrationSiteBranding } from '../contentArea/loginregistration/LoginRegistrationSite'
import { ErrorSiteBranding } from '../contentArea/errorPages/ErrorSite'
import { CommunicationAreaBranding } from '../communicationArea/CommunicationArea'
import { ContactEntryBranding } from '../communicationArea/ContactEntry'
import { SideIconBarBranding } from '../navigationArea/SideIconBar/SideIconBar';
import { SponsorsPageContentBranding } from '../contentArea/SponsorsPageContent';
import { NetworkingAreaBranding } from '../contentArea/networkingGraph/NetworkingPageContent'
import { CalendarEntryModalBranding } from '../ui/CalendarEntryModal';
import { CalendarEntryListViewBranding } from '../communicationArea/CalendarEntryListView';
import { MeetingRoomGroupType } from '../conference/ConferencePageContent';
import { ProgramPageContentBranding } from '../contentArea/program/ProgramPageContent';
import { ProgramScheduleBranding } from '../contentArea/program/Schedule';
import { ProgramSpeakersBranding } from '../contentArea/program/Speakers';
import { SystemCheckBranding } from '../systemCheck/SystemCheckBranding';
import { ConferenceTexts } from '../conference/types/ConferenceTexts';
import { GlobalTextsBranding } from '../globalStates/GlobalTextsBranding'
import { ThirdPartyResources } from './ThirdPartyResources';
import { TicketSaleBranding } from '../PreLoginApp';
import { CalendarEntryExprtBranding } from '../communicationArea/ICal';
import { NotificationBranding } from '../conference/context/NotificationContext';
import { BackendErrorBranding } from '../ui/BackendError';
import { RecommendModalBranding } from '../ui/RecommendOrganizationModal';
import { BackofficeContentBranding } from '../contentArea/backoffice/OrganizationBackofficePageContent';
import { BackofficeStaffBranding } from '../contentArea/backoffice/BackofficeStaff';
import { CouponsBranding } from '../contentArea/CouponsPageContent';
import { BackofficeLVisitorsBranding } from '../contentArea/backoffice/BackofficeVisitors';
import { BackofficeLoungeBranding } from '../contentArea/backoffice/BackofficeLounge';
import { BackofficeReportingBranding } from '../contentArea/backoffice/BackofficeReporting';
import { DeviceSwitcherBranding } from '../conference/components/DeviceSwitcher';
import { TopBannerBranding } from '../contentArea/advertisingBanner/TopBanner';
import { CrsMultiSwitcherBranding } from '../ui/CrsMultiSwitch';
import { RosterBranding } from '../conference/components/Roster';
import { SettingsTabBranding } from '../communicationArea/SettingsTab';
import { BackofficeStaffReqBranding } from '../contentArea/backoffice/BackofficeStaffReq';
import { BackofficeMeetingsBranding } from '../contentArea/backoffice/BackofficeMeetings';
import { BasisPremiumBranding } from './BasisPremiumBranding';
import { ConversationEntryBranding } from '../communicationArea/ConversationEntry';
import { CrsTimePickerBranding } from '../ui/CrsTimePicker/CrsTimePicker';
import { MediaPartnerPageContentBranding } from '../contentArea/MediaPartnersPageContent';


export interface Branding {
    configuration: Configuration
    eventTiming: EventTiming
    greenroomGoLiveFollowupDelaySec: number // TODO use time to live value returned from backend on going live instead
    pageTitle: string
    ticketSalePhasePageTitle: string
    pageDescription: string
    availableLanguages: string[]
    globalStatePopupTexts: GlobalStatePopupTextsBranding
    loginRegistrationSite: LoginRegistrationSiteBranding
    errorSite: ErrorSiteBranding
    basisPremium: BasisPremiumBranding
    communicationArea: CommunicationAreaBranding
    chatBranding: ChatBranding
    contactEntry: ContactEntryBranding
    deviceSwitcher: DeviceSwitcherBranding
    sideIconBar: SideIconBarBranding
    navigationArea: NavigationAreaBranding
    sponsorsPageContent: SponsorsPageContentBranding
    mediaPartnerPageContent: MediaPartnerPageContentBranding
    calendarEntryModalPageContent: CalendarEntryModalBranding
    calendarEntryListView: CalendarEntryListViewBranding
    calendarEntryExport: CalendarEntryExprtBranding
    myProfilePageContent: MyProfilePageContentBranding
    personDetailPageContent: PersonDetailPageContentBranding
    sayHelloModal: SayHelloModalBranding
    filterBar: FilterBarBranding
    organizationDetailPageContent: OrganizationDetailPageContentBranding
    backofficeContent: BackofficeContentBranding
    backofficeStaff: BackofficeStaffBranding
    backofficeStaffReq: BackofficeStaffReqBranding
    backofficeMeetings: BackofficeMeetingsBranding
    backofficeVisitors: BackofficeLVisitorsBranding
    backofficeLounge: BackofficeLoungeBranding
    backofficeReporting: BackofficeReportingBranding
    productDetailPageContent: ProductDetailPageContentBranding
    trademarkDetailPageContent: TrademarkDetailPageContentBranding
    eventDateDetailPageContent: EventDateDetailPageContentBranding
    productsPageContent: ProductsPageContentBranding
    newsPageContent: NewsPageContentBranding
    trademarksPageContent: TrademarksPageContentBranding
    exhibitorsPageContent: ExhibitorsPageContentBranding
    programPageContent: ProgramPageContentBranding
    programSchedule: ProgramScheduleBranding
    programSpeakers: ProgramSpeakersBranding
    pressMediaPageContent: PressMediaPageContentBranding
    businessAreaPageContent: BusinessAreaPageContentBranding
    systemCheck: SystemCheckBranding
    receptionPage: ReceptionPageSiteBranding
    meetingRoomMaxTiles: number
    meetingRoomGroupsDisablePictureOverlay: boolean
    meetingRoomGroupsTitleFontStyle: string | null
    meetingRoomGroupsHosterFontStyle: string | null
    meetingRoomGroupsDescriptionFontStyle: string | null
    meetingRoomGroups: MeetingRoomGroupType[]
    conferenceTexts: ConferenceTexts
    conferenceListTiles: ConferenceListTiles
    thirdPartyResources: ThirdPartyResources[]
    theme1Color: string
    theme2Color: string
    hasConnectWithAllParticipants: boolean
    primaryColor: string
    secondaryColor: string
    mainInfoColor: string
    mainInfoDarkColor: string
    chatListColor: string
    chatListBackgroundCommunicationAreaColor: string
    chatInputColor: string
    chatInputColorTime: string
    chatDarkenInputColor: string
    darkenOverlayColor: string
    communicationAreaSearchPlaceholderColor: string
    communicationAreaDefaultAvatarColor: string
    secondaryTextColor: string
    languageTogglerColor: string
    darkenThemeBackgroundColor: string
    darkenThemeHoverColor: string
    lightThemeHoverColor: string
    theme3ColorPrimary: string
    theme3ColorSecondary: string
    crsMultiSwitcher: CrsMultiSwitcherBranding
    bannerURL: string
    receptionFooterURL: string
    infoPage: InfosPageContentBranding
    helpPage: HelpPageContentBranding
    moreButtonColor1: string
    moreButtonColor2: string
    video1: string
    video2: string
    video3: string
    font1: string
    font2: string
    loaderBgColor: string
    loaderSpinColor: string
    customScrollbarBgColor: string
    customScrollbarBorderColor: string
    customScrollbarWidth: string
    customScrollbarHeight: string
    dangerButtonColor: string
    networkingArea: NetworkingAreaBranding
    filterButtonColor: string
    globalTexts: GlobalTextsBranding
    ticketSale: TicketSaleBranding
    notification: NotificationBranding
    backendError: BackendErrorBranding
    recommendModal: RecommendModalBranding
    topSponsors: TopSponsor[]
    ideaSponsors: TopSponsor[]
    topicBadges: [TopicBadge]
    press: LocalizedString
    coupons: CouponsBranding
    topBanner: TopBannerBranding
    guestUserBanner: GuestUserBannerBranding
    topBannerConfig: TopBannerConfig
    rulesPage: RulesPageContentBranding
    topBar: TopBarBranding
    roster: RosterBranding
    settingsTab: SettingsTabBranding
    mySchedule: MySchedulePageBranding
    hubspotScriptTag: string
    gtmNoScript: string
    gtmScriptDataLayer: string
    gtmTagScript: string
    jiraSupportDeskData: {
        visible: boolean,
        key: string
    },
    videoBackground: string
    showroomMeetingDuration: string
    defaultToggleIcon: boolean
    conversationEntry: ConversationEntryBranding
    globalOptIn: boolean
    trackingBrandingNone: TrackingBranding
    trackingBrandingBasic: TrackingBranding
    trackingBrandingStandard: TrackingBranding
    trackingBrandingPremium: TrackingBranding
    csvExportBranding: CSVExportBranding
    timePickerBranding: CrsTimePickerBranding
    contextMenuBranding: ContextMenuBranding
    dropdownStyle: {
        primaryColor: string
        primary25Color: string
        primary50Color: string
        primary75Color: string
    }
}

export interface TopicBadge {
    topicName: string
    badgeText: string
    textColor: string
    backgroundColor: string
    borderColor: string
}

export interface EventTiming {
    ticketShopOpenDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    onboardingOpenDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    reportingOpenDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    loginOpenDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    softOpeningDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    eventStartDateTime: string // ISO_8601 "2000-01-01T00:00:00+02:00"
    eventEndDateTime: string // ISO_8601 "2020-09-25T00:00:00+02:00"
    eventDateDefaultStartTime: string // HH:mm
    eventDateDefaultEndTime: string // HH:mm
    eventDays: string[] // ISO_8601 "2020-09-25T00:00:00+02:00"
    meetingDays: string[] // format same as eventDays
    eventDaysFormatPattern: LocalizedString
    eventDaysFormatPatternShort: LocalizedString
    eventDaysFormated: string[]
    eventDaysFormatedDe: string[]
    eventDaysFormatPatternWithTime: LocalizedString
    eventDaysFormatPatternEvtDetailPage: LocalizedString
}

export interface LocalizedString {
    de: string
    en: string
}

export interface PluralString {
    singular: LocalizedString
    plural: LocalizedString
}

export interface ResolvedPluralString {
    singular: string
    plural: string
}

export function getPluralString(pluralString: ResolvedPluralString, count: number, countPlaceHolder?: string) {
    const stringResource = count === 1 ? pluralString.singular : pluralString.plural
    if (countPlaceHolder) {
        return stringResource.split(`{$${countPlaceHolder}}`).join(`${count}`)
    }
    return stringResource
}

interface MeetingDurationType {
    call: number
    calenderEntry: number
}

export interface Configuration {
    topicName: string
    userPoolName: string
    sotName: string
    sotAccessToken: string
    useSubscriptionPresenceState: boolean
    networkingToggle: boolean
    ogTitle: LocalizedString
    ogDescription: LocalizedString
    browserTranslation: string
    speakerPersonFunctions: string[] // TODO delete me when DEP-1966 done
    defaultStaffPersonFunction: string // TODO delete me when DEP-1966 done
    calendarEntryParticipantLimit: number // It has to be equal or greater then chatParticipantLimit
    showPrivacyModelForEventDates: boolean
    chatParticipantLimit: number
    virtualCafeParticipantLimit: number
    greenRoomParticipantLimit: number
    showRoomParticipantLimit: number
    roundTableParticipantLimit: number
    meetingDurations: MeetingDurationType //durations for different types of meeting (in seconds)
    eventDateParticipation: boolean // speaker component and list on lobby to show eventdate related persons
    detailSectionToggle: boolean // backoffice toggle for displaying organization's detail section parts
}

export interface ConferenceListTiles {
    gradientDivBackgroundFirst: string
    gradientDivBackgroundSecond: string
    headerStickyBackground: string
    cardBackground: string
    corouselBackground: string
    sponsorsBackground1: string
    sponsorsBackground2: string
    sponsorsBackground3: string
    sponsorsBackground4: string
    sponsorsBackground5: string
}

export interface TopSponsor {
    id: string
    name: string
    logo: string
    useCustomName: boolean
    order: number
}

export interface ChatBranding {
    groupChat: LocalizedString
    callChatDefaultName: LocalizedString
    youText: LocalizedString
    detailsText: LocalizedString
    newChatText: LocalizedString
    newGroupText: LocalizedString
    searchForPeopleText: LocalizedString
    participantsText: LocalizedString
    newGroupChatText: LocalizedString
    setGroupNameText: LocalizedString
    addGroupDescriptionText: LocalizedString
    findParticipantsText: LocalizedString
    startChatText: LocalizedString
    addParticipantsText: LocalizedString
    disableAddedText: LocalizedString
    exitGroupText: LocalizedString
    exitGroupChatText: LocalizedString
    cancelText: LocalizedString
    exitText: LocalizedString
    muteInsteadText: LocalizedString
    closeText: LocalizedString
    noResultFoundText: LocalizedString
    titleMeeting: LocalizedString
    meetingCancelled: LocalizedString
    roundedButtonStartChatBgColor: string
    roundedButtonStartChatTextColor: string
    roundedButtonStartChatBgDisabledColor: string
    roundedButtonStartChatBgOnHoverColor: string
    maxParticipants: number
}

export interface TopBarBranding {
    fontWeight: string
    textColor: string
    background: string
    searchTitleColor: string
    breadcrumbTextTransform: string
    breadcrumbLetterSpacing: string
}

export interface TrackingBranding {
    detailpage: boolean
    showroom: boolean
    calendarEntry: boolean
    interest: boolean
    recommend: boolean
    lounge: boolean
    links: boolean
    media: boolean
    stream: boolean
    schedule: boolean
    coupon: boolean
    press: boolean
    magazine: boolean
    zipCode: boolean
    city: boolean
    showVisitorsTab: boolean
    showGuestsSelect: boolean
    showVisitorsSelect: boolean
    showLeadsSelect: boolean
    showContactsSelect: boolean
    showDbSelect: boolean
    showReportTab: boolean
    enableDownload: boolean
}

export interface CSVExportBranding {
    firstName: LocalizedString
    lastName: LocalizedString
    company: LocalizedString
    jobTitle: LocalizedString
    zipCode: LocalizedString
    city: LocalizedString
    country: LocalizedString
    email: LocalizedString
    phone: LocalizedString
    interests: LocalizedString
    optIn: LocalizedString
    detailPage: LocalizedString
    showroom: LocalizedString
    requestedMeeting: LocalizedString
    registeredInterest: LocalizedString
    recommendation: LocalizedString
    virtualCafe: LocalizedString
    virtualSpace: LocalizedString
    link: LocalizedString
    media: LocalizedString
    livestream: LocalizedString
    conferenceSlot: LocalizedString
    goodieBag: LocalizedString
    lastInteraction: LocalizedString
    markedBy: LocalizedString
    markedAt: LocalizedString
    press: LocalizedString
    magazine: LocalizedString
}

declare const branding: Branding
function getBranding(): Branding {
    return branding
}
export default getBranding()
