
import vCardFactory from 'vcards-js'
import { saveAs } from 'file-saver'
import { Company } from "../backendServices/Types"
import branding from '../branding/branding'
import { CsvHeaderType } from './CommunicationArea'


export const saveVCard = (persons: any[]) => {
    const text = persons.map(createOneContact).join('\r\n')
    const name = (persons.length === 1 ? getName(persons[0]) : 'contacts') + '.vcf'
    var type = window.navigator.appVersion.indexOf("Win") !== -1 ? "text/x-vcard;charset=windows-1252" : "text/x-vcard;charset=utf-8"
    const blob = new Blob([text], { type: type })
    saveAs(blob, name)
}

export const saveContactCSV = (persons: any[], csvContactHeader: string) => {
    let text = csvContactHeader + '\n'
    text += persons.map(createOneContactCSV).join('\n')
    const name = (persons.length === 1 ? getName(persons[0]) : 'contacts') + '.csv'
    var type = "text/csv;charset=utf-8"
    const blob = new Blob([text], { type: type })
    saveAs(blob, name)
}

export const saveExhibitorVCard = (organization: Company, note: string) => {
    const text = createExhibitorContact(organization, note)
    const name = organization.name + '.vcf'
    var type = window.navigator.appVersion.indexOf("Win") !== -1 ? "text/x-vcard;charset=windows-1252" : "text/x-vcard;charset=utf-8"
    const blob = new Blob([text], { type: type })
    saveAs(blob, name)
}

function createOneContact(person: any) {
    const vc = vCardFactory()
    vc.firstName = person.firstName
    vc.lastName = person.lastName
    vc.organization = person.company
    vc.title = person.position
    vc.workEmail = person.email
    vc.workPhone = person.phone
    vc.cellPhone = person.mobile
    vc.socialUrls.facebook = person.facebook
    vc.socialUrls.linkedIn = person.linkedIn
    vc.socialUrls.twitter = person.twitter
    vc.note = person.infotext
    //vc.socialUrls.xing = person.xing
    if (person.logoUrl) {
        vc.photo.attachFromUrl(person.logoUrl, 'jpeg')
    }
    vc.formattedName = getName(person)
    return vc.getFormattedString()
}

function createOneContactCSV(person: any) {
    const data: Array<string> = []
    const header = branding.communicationArea.csvContactHeader
    header.forEach((item: CsvHeaderType) => {
        switch (item.id) {
            case "first_name":
                data.push(person.firstName); break
            case "last_name":
                data.push(person.lastName); break
            case "company":
                data.push(person.company); break
            case "position":
                data.push(person.position); break
            case "email":
                data.push(person.email); break
            case "phone":
                data.push(person.phone); break
            case "mobile":
                data.push(person.mobile); break
            case "facebook":
                data.push(person.facebook); break
            case "linkedin":
                data.push(person.linkedIn); break
            case "twitter":
                data.push(person.twitter); break
            case "info_text":
                data.push(person.infotext); break
            case "logo":
                data.push(person.logoUrl); break
            case "formatted_name":
                data.push(getName(person)); break
            default: break;
        }
    })
    return data.join(", ")
}


function createExhibitorContact(exhibitor: any, note: string) {
    const vc = vCardFactory()

    vc.firstName = exhibitor.name

    vc.workAddress.street = exhibitor.adress1
    vc.workAddress.postalCode = exhibitor.postCode
    vc.workAddress.countryRegion = exhibitor.country
    vc.workAddress.city = exhibitor.city

    vc.workEmail = exhibitor.email
    vc.workPhone = exhibitor.phone

    vc.url = exhibitor.web

    vc.note = note

    if (exhibitor.pictureURL) {
        vc.photo.attachFromUrl(exhibitor.pictureURL, 'JPEG')
    }
    else if (exhibitor.logo) {
        vc.photo.attachFromUrl(exhibitor.logo, 'JPEG')
    }

    return vc.getFormattedString()
}

function getName(person: any) {
    return [person.firstName, person.lastName]
        .filter(Boolean)
        .join(" ");
}

