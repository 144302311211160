import React, { useEffect, useRef, useState } from "react"
import { Col, Row, Tooltip } from "react-bootstrap"
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger"
import styled from "styled-components"
import { EntityType } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { BookmarkWithToggle } from "../../../ui/BookmarkWithToggle"
import ScrollBarComponent from "../../../ui/ScrollBar"
import { DetailNavLink } from "../DetailNavLink"
import { ScrollableDiv } from "./DetailPageSections"
import { HeaderTitle } from "./StyledComponents"

export interface EntityRowData {
    id: string
    name: string
    initials: string
    pictureURL: string
    description: { teaser: string }
}
interface EntityRowsProps {
    type: EntityType
    headerTitle: string
    rowsData: EntityRowData[]
}

export const DetailPageEntityRows: React.FunctionComponent<EntityRowsProps> = (props) => {
    const [position, setPosition] = useState(0);
    const [value, setValue] = useState(0);
    const scrollRef = useRef<HTMLDivElement>(null)
    const maxScrollRange = scrollRef.current?.scrollHeight! - scrollRef.current?.clientHeight!;
    const width = maxScrollRange !== 0 ? "95%" : "100%";

    function sortAlphabetically(): EntityRowData[] {
        return props.rowsData.sort(function (a: EntityRowData, b: EntityRowData) {
            return a.name.localeCompare(b.name)
        });
    }

    const rows = sortAlphabetically()

    useEffect(() => {
        scrollRef.current?.scrollTo(0, position)
    }, [position])

    return (
        <Col xs={12}>
            <Row className="mt-5">
                <Col xs={12} className="p-0 mb-4">
                    <HeaderTitle>{`${props.headerTitle} (${props.rowsData.length})`}</HeaderTitle>
                </Col>
                <ScrollableDiv ref={scrollRef} onScroll={() => setValue(scrollRef.current?.scrollTop!)} width={width}>
                    {
                        rows.map((rowData) => <DetailPageEntityRow type={props.type} rowData={rowData} key={rowData.id} />)
                    }
                </ScrollableDiv>
                <ScrollBarComponent value={value} maxScrollRange={maxScrollRange ? maxScrollRange : 0} onScroll={(val: number) => { setPosition(val); setValue(val); }} isVertical={true}></ScrollBarComponent>
            </Row>
        </Col>
    )
}

const DetailRowEntryRoot = styled.div`
    display: flex;
    border-bottom: 1px solid ${branding.mainInfoColor};
`

const StyledDetailNaviLink = styled(DetailNavLink)`
    margin-top: 7px;
    margin-bottom: 8px; 
`

const DetailRowContent = styled.div`
    padding-left: 20px;
`

const RowEntryNameLabel = styled.div`
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 13px;
`

const RowEntryTeaserLabel = styled.div`
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-top: 3px;
`

const MenuPanel = styled.div`
    display: flex;
    margin-left: auto;
    color: ${branding.primaryColor};
`

const MenuButton = styled.div`
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    height: 25px;
    width: 25px;

    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }
`

const LogoRoot = styled.div<{ hasPictureUrl: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D9D9D9;
    width: 60px;
    height: 60px;
    background: ${props => props.hasPictureUrl ? "#FFF" : "lightgrey"};
`
const LogoDiv = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`

interface EntityRowProps {
    type: EntityType
    rowData: EntityRowData
}

export const DetailPageEntityRow: React.FunctionComponent<EntityRowProps> = (props) => {
    const strings = useLanguageState().getStrings()
    return (<>
        <DetailRowEntryRoot>
            <StyledDetailNaviLink id={props.rowData.id} type={props.type} name={props.rowData.name}>
                <LogoRoot hasPictureUrl={props.rowData.pictureURL !== null}>
                    {props.rowData.pictureURL ? <LogoDiv src={props.rowData.pictureURL} alt="" /> : <h2>{props.rowData.initials}</h2>}
                </LogoRoot>
            </StyledDetailNaviLink>
            <DetailRowContent>
                <DetailNavLink id={props.rowData.id} type={props.type} name={props.rowData.name}>
                    <RowEntryNameLabel>{props.rowData.name}</RowEntryNameLabel>
                    <RowEntryTeaserLabel>{props.rowData?.description?.teaser}</RowEntryTeaserLabel>
                </DetailNavLink>
            </DetailRowContent>
            <MenuPanel>
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-share" style={{ fontFamily: branding.font1 }}>{strings.backofficeStaff.bookmark}</Tooltip>}>
                    <MenuButton><BookmarkWithToggle newBookmarkItem={true} fontSize={"25px"} color={branding.sideIconBar.sideIconColorDark} stroke={branding.sideIconBar.sideIconColorDark} type={props.type} id={props.rowData.id} name={props.rowData.name} /></MenuButton>
                </OverlayTrigger>
            </MenuPanel>
        </DetailRowEntryRoot>
    </>
    )
}