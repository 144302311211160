import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button, Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import { IconFacebook, IconTwitter, IconLinkedIn, IconXing, IconInstagram, IconYouTube, IconAdd, IconRemove, IconCheckmarkGreen, IconCloseRed, IconIndicatorSuccessBlack, IconIndicatorErrorBlack, IconSearchSmall, IconArrowUpTimezoneDropdown } from "../../ui/Icons"
import { useLoggedInState } from "../../globalStates/LoggedInUser"

import { getInterest, updateProfileData, changeProfileImage, deleteProfileImage, updateUserValues, BackendServiceError } from "../../backendServices/BackendServices"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useUsersPresence } from "../../globalStates/ConnectedUsers"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import CenteredLoader from "../../ui/CenteredLoader"
import { defaultLogger as logger } from "../../globalStates/AppState"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import DataUpdateAlert from "../../ui/DataUpdateAlert"
import { TextField, MultiLineTextField } from "../../ui/TextField"
import TextLimit from "../../ui/TextLimit"
import { MyProfilePageMode } from "./MyProfilePageContent"
import { findTimezoneName, getTimezones } from "../../DateUtils"
import { useAppState } from "../../globalStates/AppState"
import moment from "moment-timezone"
import { Category } from "../../backendServices/Types"
import { getCountries, findCountryName } from "./CountrySwitcherHelper"
import { getLanguages, findLanguageName } from "./LanguageSwitcherHelper"
import NetworkingToggle from "../../communicationArea/components/NetworkingToggle"
import Select, { ActionMeta, OptionTypeBase, ValueType } from 'react-select'


/* #region Special search bar */

interface SpecialSearchBarProps {
    title: string
    searchParam: string
    setSearchParam: (value: string) => void
    dropdownSearch?: boolean
    dropdownOnClick?: () => void
}

const SpecialSearchBar: React.FunctionComponent<SpecialSearchBarProps> = React.memo((props: SpecialSearchBarProps) => {
    const [iconColor, setIconColor] = useState<string>("gray")

    return <div onMouseEnter={() => setIconColor(branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor)}
        onMouseLeave={() => {
            if (props.searchParam.length <= 0)
                setIconColor("gray")
        }}>
        <TextField
            placeholder={props.title}
            value={props.searchParam}
            width="100%"
            fontSize="16px"
            setValue={props.setSearchParam}
            borderAround="1px solid gray"
            outline={props.dropdownSearch ? "1px solid" : "2px solid"}
            startAdornment={!props.dropdownSearch && IconSearchSmall({ width: "20", height: "20", fill: iconColor })}
            endAdornment={props.dropdownSearch &&
                <div style={{ marginTop: "3px" }} onClick={() => { if (props.dropdownOnClick) props.dropdownOnClick() }}>
                    {IconArrowUpTimezoneDropdown({ width: "15", height: "15", fill: iconColor })}
                </div>
            }
        />
    </div>
})

/* #endregion */



/* #region EditProfile header */

interface ProfileImageProps {

    logoUrl: string
    setLogoUrl: (value: string) => void
    firstName: string
    setFirstName: (value: string) => void
    lastName: string
    setLastName: (value: string) => void

    setIsProfileImageDeleted: (value: boolean) => void
    setIsProfileImageChanged: (value: boolean) => void

    setImageDimensions: (values: any) => void

    setImageSize: (value: number) => void
    setImageFile: (value: any) => void
}

const ProfileImage: React.FunctionComponent<ProfileImageProps> = React.memo((props: ProfileImageProps) => {
    const strings = useLanguageState().getStrings()
    let imageInput: HTMLInputElement | null

    function triggerProfileImageInputClick() {
        if (imageInput) {
            imageInput.click()
        }
    }

    function handleProfileImageChange() {
        if (imageInput && imageInput !== null && imageInput.files) {
            props.setIsProfileImageDeleted(false)
            props.setIsProfileImageChanged(true)

            var binaryData = []
            binaryData.push(imageInput.files[0])

            const objectURL = URL.createObjectURL(new Blob(binaryData, { type: "image" }))
            props.setImageSize(imageInput.files[0].size)
            props.setLogoUrl(objectURL)
            props.setImageFile(imageInput.files[0])

            var reader = new FileReader();
            reader.onloadend = function () {

                var image = new Image()
                image.src = objectURL
                image.onload = function () {
                    props.setImageDimensions({ height: image.naturalHeight, width: image.naturalWidth })
                }
            }

            reader.readAsDataURL(imageInput.files[0])

        }
    }

    function handleProfileImageDelete() {
        props.setIsProfileImageDeleted(true)
        props.setIsProfileImageChanged(false)
        props.setLogoUrl("")
    }

    return <>
        <Row className="mb-4 mt-20 ">
            <Col xs={12} md={6} style={{ display: "flex" }} className="mt-10 justify-content-start">
                <LabelText>{strings.myProfilePageContent.profilePictureLabel}</LabelText>
            </Col>

            <Col xs={6} md={3}>
            </Col>
        </Row>
        <Row className="mb-1">
            <Col xs={8} md={4} style={{ display: "flex" }} className="mt-10 justify-content-start">
                <Row>
                    <Col xs={9} md={4} style={{ display: "flex" }} className="mt-10 justify-content-start">
                        <UserImageDiv>
                            <AvatarWithDefault size={70} src={props.logoUrl} alt={[props.firstName, props.lastName].join(" ") ?? "#"} />
                        </UserImageDiv>
                    </Col>
                    <Col xs={12} md={6} className="mt-10 justify-content-start">
                        <Row>
                            <input className="d-none" ref={input => imageInput = input} type="file" value="" onChange={handleProfileImageChange} accept=".jpg,.jpeg,.png" />
                            <WhiteButton onClick={triggerProfileImageInputClick}>
                                {strings.myProfilePageContent.uploadNewButtonText}
                            </WhiteButton>
                        </Row>
                        <Row>
                            <div style={{ marginLeft: "5px" }}>
                                <LabelText style={{ fontSize: "11.5px", fontWeight: "normal", color: "#808080" }}>
                                    {strings.myProfilePageContent.formatsText}
                                </LabelText>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={3} md={2}>

                    </Col>
                </Row>
            </Col>
            <Col xs={3} md={2}>
                <DeleteCurrentPictureButton onClick={() => handleProfileImageDelete()}>
                    {strings.myProfilePageContent.deleteCurrentPictureButtonText}
                </DeleteCurrentPictureButton>
            </Col>
        </Row>
    </>
})

/* #endregion */

const TextFieldContainer = styled.div`
    margin: 0.5em;
    width: 100%;
`

/* #region Social media row */
interface SocialMediaProps {
    labelText: string
    type: SocialMediaType
    link: string
    setLink: (value: string) => void
    icon: any
    invalidLinkWarning: string
}

export enum SocialMediaType {
    FACEBOOK = "FACEBOOK",
    TWITTER = "TWITTER",
    INSTAGRAM = "INSTAGRAM",
    YOUTUBE = "YOUTUBE",
    LINKEDIN = "LINKEDIN",
    XING = "XING",
    PINTEREST = "PINTEREST"
}

function isSocialMediaLinkCorrect(link: string, socialMedia: SocialMediaType): boolean {
    switch (socialMedia) {
        case SocialMediaType.FACEBOOK: {
            return link.includes("facebook.com/")
        }
        case SocialMediaType.TWITTER: {
            return link.includes("twitter.com/")
        }
        case SocialMediaType.LINKEDIN: {
            return link.includes("linkedin.com/")
        }
        case SocialMediaType.XING: {
            return link.includes("xing.com/")
        }
        case SocialMediaType.INSTAGRAM: {
            return link.includes("instagram.com/")
        }
        case SocialMediaType.YOUTUBE: {
            return link.includes("youtube.com/")
        }
        case SocialMediaType.PINTEREST: {
            return link.includes("pinterest.com/") || link.includes("pinterest.de/")
        }
        default: {
            return false
        }
    }
}

const SocialMedia: React.FunctionComponent<SocialMediaProps> = React.memo((props: SocialMediaProps) => {

    return <Col xs={12} md={6}>
        <LabelText>{props.labelText}</LabelText>
        <TextFieldContainer>
            <TextField
                disabled={branding.myProfilePageContent.editDisabledFields?.includes(props.type)}
                width="100%"
                height="30px"
                fontSize="16px"
                fontFamily={branding.mainInfoColor}
                value={props.link}
                setValue={props.setLink}
                placeholder=""
                startAdornment={props.icon}
                endAdornment={
                    props.link.length === 0 ? null :
                        (
                            isSocialMediaLinkCorrect(props.link, props.type) ?
                                <>
                                    {IconCheckmarkGreen}
                                </> :
                                <OverlayTrigger
                                    placement="top-end"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id={props.type.toLowerCase() + "-tooltip"} style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                            {props.invalidLinkWarning}
                                        </Tooltip>}>
                                    <div>
                                        {IconCloseRed}
                                    </div>
                                </OverlayTrigger>
                        )
                } />
        </TextFieldContainer>
    </Col>
})
/* #endregion */


/* #region Profile information column */

interface ProfileInformationProps {
    required?: boolean
    labelText: string
    value: string
    setValue: (value: string) => void
    checkValue: boolean
    invalidValueWarning: string
    disabledFieldMarker: DisabledFields

    columnXs?: number
    columnMd?: number
}

const ProfileInformation: React.FunctionComponent<ProfileInformationProps> = React.memo((props: ProfileInformationProps) => {
    const strings = useLanguageState().getStrings()
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    return <Col xs={props.columnXs ?? 24} md={props.columnMd ?? 12}>
        <LabelText>
            {props.labelText} {props.required && <span style={{ color: "red" }}>*</span>}
        </LabelText>

        <TextFieldContainer>
            <TextField
                disabled={branding.myProfilePageContent.editDisabledFields?.includes(props.disabledFieldMarker)}
                width="100%"
                height="30px"
                fontSize="16px"
                fontFamily={branding.mainInfoColor}
                value={props.value}
                setValue={props.setValue}
                placeholder=""
                endAdornment={
                    (props.required && props.value.length === 0) ?
                        <OverlayTrigger
                            show={showTooltip}
                            placement="top-end"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id={props.disabledFieldMarker.toLowerCase() + "required-tooltip"} style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                    {strings.myProfilePageContent.oneRequiredFieldEmptyWarning}
                                </Tooltip>}>
                            <>
                                <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                    {IconCloseRed}
                                </div>
                            </>
                        </OverlayTrigger> :
                        (props.value.length === 0 ?
                            null :
                            (
                                props.checkValue ?
                                    <>
                                        {IconCheckmarkGreen}
                                    </>
                                    :
                                    <OverlayTrigger
                                        show={showTooltip}
                                        placement="top-end"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            (<Tooltip id={props.disabledFieldMarker.toLowerCase() + "-tooltip"} style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                                <>
                                                    {props.invalidValueWarning}
                                                </>
                                            </Tooltip>)}>
                                        <>
                                            <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                                {IconCloseRed}
                                            </div>
                                        </>
                                    </OverlayTrigger>
                            )
                        )
                } />
        </TextFieldContainer>
    </Col>
})




/* #endregion */

/* #region Time zone switcher */
export const SelectThemeCustom = (theme: any) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: branding.dropdownStyle.primaryColor,
        primary25: branding.dropdownStyle.primary25Color,
        primary50: branding.dropdownStyle.primary50Color,
        primary75: branding.dropdownStyle.primary75Color
    }
})

const StyledSelect = styled(Select)`
    width: 100%;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    font-size: 16px;
    margin-top: 13px;
    margin-left: 7px;
`

const TimeZoneSwitcher: React.FunctionComponent = React.memo(() => {
    const appState = useAppState()
    const strings = useLanguageState().getStrings()
    const timezones = getTimezones()
    const timezonesCustom = timezones.map(t => { return { label: t.name.slice(0, t.name.indexOf(" - ") || 150), value: t.timezones[0] } })
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone ?? moment.tz.guess())
    const timezoneNameFull = findTimezoneName(selectedTimezone)
    const timezoneName = timezoneNameFull.slice(0, timezoneNameFull.indexOf(" - ") || 150)
    const selectedTimezoneCustom = { label: timezoneName, value: selectedTimezone }

    function onTimezoneSelect(timezone: string) {
        setSelectedTimezone(timezone)
        appState.setTimeZone(timezone)
    }

    return <Col xs={12} md={6}>
        <LabelText>
            {strings.myProfilePageContent.timeZoneLabel}
        </LabelText>

        <StyledSelect
            placeholder={timezoneName}
            isMulti={false}
            isSearchable={true}
            options={timezonesCustom}
            value={selectedTimezoneCustom}
            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                if (value !== null && action.action === "select-option") {
                    const option = value as OptionTypeBase
                    if (selectedTimezone !== option.value)
                        onTimezoneSelect(option.value)
                }
            }}
            theme={SelectThemeCustom}
        />
    </Col>
})

/* #endregion */

/* #region Language switcher */
interface LanguageSwitcherProps {
    languages: any[]
    setLanguages: (value: any[]) => void
}

const LanguageSwitcher: React.FunctionComponent<LanguageSwitcherProps> = React.memo((props: LanguageSwitcherProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const languages = getLanguages(lang)
    const languagesFiltered = languages.map(lng => { return { label: lang === "de" ? lng.de : lng.en, value: lng.code } })
    const [selectedLanguages, setSelectedLanguages] = useState<(OptionTypeBase | null)[]>(props.languages.map(langCode => { return { label: findLanguageName(langCode, lang), value: langCode } }))

    useEffect(() => {
        setSelectedLanguages(props.languages.map(langCode => { return { label: findLanguageName(langCode, lang), value: langCode } }))
    }, [lang]) // eslint-disable-line 


    return <Col xs={12} md={6}>
        <LabelText>
            {strings.myProfilePageContent.languagesLabel}
        </LabelText>

        <StyledSelect
            placeholder={strings.myProfilePageContent.languagesSwitcherText}
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            options={languagesFiltered}
            value={selectedLanguages}
            onChange={(value: ValueType<OptionTypeBase, boolean>[], action: ActionMeta<OptionTypeBase>) => {
                if (value !== null) {
                    const newLanguages = value.map(x => { return (x as OptionTypeBase).value })
                    setSelectedLanguages(value)
                    props.setLanguages(newLanguages)
                }
            }}
            theme={SelectThemeCustom}
        />
    </Col>
})

/* #endregion */


/* #region  Country switcher */
interface CountrySwitcherProps {
    country: string
    setCountry: (value: string) => void
}

const CountrySwitcher: React.FunctionComponent<CountrySwitcherProps> = React.memo((props: CountrySwitcherProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const countries = getCountries(lang)
    const countriesFiltered = countries.map(c => { return { label: lang === "de" ? c.de : c.en, value: c.code } })

    return <Col xs={12} md={6}>
        <LabelText>
            {strings.myProfilePageContent.countryLabel}
        </LabelText>
        <StyledSelect
            placeholder={strings.myProfilePageContent.countrySwitcherText}
            isMulti={false}
            isSearchable={true}
            isClearable={true}
            options={countriesFiltered}
            value={props.country !== "" ? { label: findCountryName(props.country, lang), value: props.country } : null}
            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                if (value !== null && action.action === "select-option") {
                    const option = value as OptionTypeBase
                    if (props.country !== option.value)
                        props.setCountry(option.value)
                } else
                    props.setCountry("")
            }}
            theme={SelectThemeCustom}
        />
    </Col>
})
/* #endregion */


/* #region  Interests */

const InterestsTitleRow = styled(Row)`
    margin: 30px 0 0 0;
`

const InterestsViewRow = styled(Row)`
    display: flex;
    margin-bottom: 50px;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const SelectedCategoryItem = styled.div`
    cursor: pointer;
    display: inline-block;
    padding: .5rem;
    font-size: 14px;
    border: 2px solid ${branding.mainInfoColor};
    font-weight: normal;
    text-transform: unset;
    text-align: center;
    margin: .3rem;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:hover {
        color: ${branding.dangerButtonColor};
        border: 2px solid ${branding.dangerButtonColor};
  }
`

const AvailableCategoryItem = styled.div`
    cursor: pointer;
    display: inline-block;
    padding: .4rem;
    font-size: 14px;
    color: #808080;
    border: 2px solid #808080;
    font-weight: normal;
    text-transform: unset;
    text-align: center;
    margin: .3rem;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:hover {
      color: #00B300;
      border: 2px solid #00B300;
  }
`

const SaveLoader = styled(CenteredLoader)`
    height: 120px;
`

const CategoryItemBadge = styled.span`
  position: relative;
  bottom: 17px;
  left: 14px;
`

interface ProfileBiographyProps {
    labelText: string
    biography: string
    setBiography: (value: string) => void
}

const ProfileBiography: React.FunctionComponent<ProfileBiographyProps> = React.memo((props: ProfileBiographyProps) => {
    const maxTextLength: number = 1000

    return <>
        <br />
        <br />
        <Row>
            <Col xs={24} md={12}>
                <LabelText>{props.labelText}</LabelText>
                <TextFieldContainer>
                    <MultiLineTextField width="100%" height="100px" fontSize="16px" fontFamily={branding.mainInfoColor} value={props.biography} setValue={props.setBiography} placeholder="" rows={6} maxLength={maxTextLength} />
                </TextFieldContainer>
            </Col>
        </Row>
        <Row>
            <Col xs={24} md={12}>
                <TextLimit textLength={props.biography.length || 0} maxTextLength={maxTextLength} />
            </Col>
        </Row>
    </>
})




interface ProfileCategoriesListProps {
    items: any[]
    searchParam: string
    strings: any
    isNumberLimited: boolean
    limitNumber: number
    selectedCategoriesNumber: number
    addToMyCategories: (item: any) => void
}

const ProfileCategoriesList: React.FunctionComponent<ProfileCategoriesListProps> = React.memo((props: ProfileCategoriesListProps) => {
    const [showMore, setShowMore] = useState<boolean>(false)

    return <Col xs={24} md={12}>
        {((showMore || props.searchParam.length > 0) ? props.items : props.items.slice(0, branding.myProfilePageContent.numberOfCategories)).map((category, key) => {
            return (
                <AvailableCategoryItem
                    key={key}
                    onClick={() => props.addToMyCategories(category)}
                    style={{
                        pointerEvents: (props.isNumberLimited && props.limitNumber <= props.selectedCategoriesNumber) ? "none" : "auto",
                        cursor: (props.isNumberLimited && props.limitNumber <= props.selectedCategoriesNumber) ? "not-allowed" : "pointer"
                    }}>
                    {category.name}
                    <CategoryItemBadge>{IconAdd}</CategoryItemBadge>
                </AvailableCategoryItem>
            );
        })
        }
        {(!showMore && props.searchParam.length === 0) &&
            <WhiteButton onClick={() => setShowMore(true)}>
                {props.strings.showMoreButtonText}
            </WhiteButton>
        }
        {(showMore && props.searchParam.length === 0) &&
            <WhiteButton onClick={() => setShowMore(false)}>
                {props.strings.showLessButtonText}
            </WhiteButton>
        }
    </Col>
})

interface ProfileCategoriesProps {
    type: CategoryType
    data: CategoryData
    setCategoryBindings: (categories: Category[]) => void
    limitNumberOfSelectedCategories?: boolean
}

export enum CategoryType {
    INTERESTS = "INTERESTS",
    OFFERS = "OFFERS",
    NEEDS = "NEEDS"
}

const ProfileCategories: React.FunctionComponent<ProfileCategoriesProps> = React.memo((props: ProfileCategoriesProps) => {
    const langState = useLanguageState()
    const strings = langState.getStrings().myProfilePageContent
    const lang = langState.getLanguage()

    const [searchParam, setSearchParam] = useState<string>("")

    function addToMyCategories(selectedDataSet: Category[], category: Category) {
        selectedDataSet.push(category)
        props.setCategoryBindings(selectedDataSet)
    }

    function removeFromMyCategories(selectedDataSet: Category[], category: Category) {
        selectedDataSet = selectedDataSet.filter(i => i !== category)
        props.setCategoryBindings(selectedDataSet)
    }

    function getLimitNumber(): number {
        if (props.type === CategoryType.OFFERS) {
            return branding.myProfilePageContent.numberOfOffers
        }
        else if (props.type === CategoryType.NEEDS) {
            return branding.myProfilePageContent.numberOfNeeds
        }
        else {
            return 5
        }
    }



    let availableCategoriesToShow: Category[];
    let workingDataSet: Category[] = [];
    let selectedDataSet: Category[] = [];
    switch (props.type) {
        case CategoryType.INTERESTS:
            workingDataSet = props.data.interests
            selectedDataSet = props.data.selectedInterests
            break
        case CategoryType.OFFERS:
            workingDataSet = props.data.offerings
            selectedDataSet = props.data.selectedOfferings
            break
        case CategoryType.NEEDS:
            workingDataSet = props.data.lookingfor
            selectedDataSet = props.data.selectedLookingfor
            break
    }
    workingDataSet = workingDataSet.filter(i => !selectedDataSet.includes(i));

    availableCategoriesToShow = workingDataSet.filter((i: any) => i.name.toLowerCase().startsWith(searchParam.toLowerCase()))


    return <Row className="ml-20">
        <Col xs={24} md={12}>
            {availableCategoriesToShow.length > 0 &&
                <>
                    <InterestsTitleRow key="title">
                        <LabelText>
                            {getCategorySectionTitle(strings, props.type) +
                                (props.limitNumberOfSelectedCategories ? " (" + (selectedDataSet.length || 0) + "/" + getLimitNumber() + ")"
                                    : "")
                            }
                        </LabelText>

                    </InterestsTitleRow>

                    <div style={{ margin: "1rem" }}>
                        <InterestsViewRow key="selected-categories">
                            <Col xs={24} md={12}>
                                {selectedDataSet.map((category, key) => {
                                    return (<>
                                        <SelectedCategoryItem
                                            key={key}
                                            onClick={() => removeFromMyCategories(selectedDataSet, category)}>
                                            {category.name}
                                            <CategoryItemBadge>{IconRemove}</CategoryItemBadge>
                                        </SelectedCategoryItem>

                                    </>
                                    );
                                })
                                }
                            </Col>
                        </InterestsViewRow>

                        <Row key="search">
                            <Col xs={24} md={12}>
                                <div style={{ marginLeft: "5px", marginBottom: "30px" }}>
                                    <SpecialSearchBar searchParam={searchParam} setSearchParam={setSearchParam} title={strings.searchPlaceholder} />

                                </div>
                            </Col>
                        </Row>

                        {
                            props.type === CategoryType.INTERESTS &&
                            <InterestsViewRow key="available-categories">
                                <ProfileCategoriesList
                                    items={availableCategoriesToShow}
                                    searchParam={searchParam}
                                    isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                    limitNumber={getLimitNumber()}
                                    selectedCategoriesNumber={selectedDataSet.length}
                                    addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                    strings={strings}
                                />
                            </InterestsViewRow>
                        }

                        {
                            (props.type === CategoryType.OFFERS || props.type === CategoryType.NEEDS) &&
                            <InterestsViewRow key="available-categories">
                                {branding.myProfilePageContent.offersNeedsCategories.length > 0 &&
                                    branding.myProfilePageContent.offersNeedsCategories.filter(x => x.id !== "default").map((parentCategory, key) => {
                                        let parentCategoryItems = availableCategoriesToShow.filter((i: any) => i.parent === parentCategory.id)
                                        return <div key={key}>
                                            {parentCategoryItems.length > 0 &&
                                                <>
                                                    <LabelText style={{ marginLeft: "20px", fontWeight: "normal" }}>
                                                        {lang === "de" ? parentCategory.name.de : parentCategory.name.en}
                                                    </LabelText>

                                                    <ProfileCategoriesList
                                                        items={parentCategoryItems}
                                                        searchParam={searchParam}
                                                        isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                                        limitNumber={getLimitNumber()}
                                                        selectedCategoriesNumber={selectedDataSet.length}
                                                        addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                                        strings={strings}
                                                    />
                                                </>
                                            }
                                        </div>

                                    })
                                }
                                {branding.myProfilePageContent.offersNeedsCategories.length === 0 &&
                                    <InterestsViewRow key="available-categories">
                                        <ProfileCategoriesList
                                            items={availableCategoriesToShow}
                                            searchParam={searchParam}
                                            isNumberLimited={props.limitNumberOfSelectedCategories ?? false}
                                            limitNumber={getLimitNumber()}
                                            selectedCategoriesNumber={selectedDataSet.length}
                                            addToMyCategories={(category) => addToMyCategories(selectedDataSet, category)}
                                            strings={strings}
                                        />
                                    </InterestsViewRow>
                                }
                            </InterestsViewRow>
                        }


                    </div>
                </>
            }
        </Col>
    </Row >
})


export function getCategorySectionTitle(strings: any, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return strings.interestsLabel

        case CategoryType.OFFERS:
            return strings.imOfferingLabel

        case CategoryType.NEEDS:
            return strings.imLookingToBuyLabel

        default:
            return strings.interestsLabel
    }
}

/* #endregion */


/* #region  EditProfile global */
const EditMyProfileRoot = styled.div`
    color: ${branding.mainInfoColor};
`

const UserImageDiv = styled.div`
    display: flex;
    align-items: center;
    width:80px;
    height:80px;
    object-fit: contain;

    .avatar-styled {
        width: 80px;
        height: 80px;
        line-height: 80px;
    }

    @media (max-width: 1600px) {
        width: 70px;
        height: 70px;

        .avatar-styled {
        width: 70px;
        height: 70px;
        line-height: 70px;
        }
    }

    @media (max-width: 1400px) {
        width: 60px;
        height: 60px;

        .avatar-styled {
            width: 60px;
            height: 60px;
            line-height: 60px;
        }
    }

    @media (max-width: 1200px) {
        width: 50px;
        height: 50px;

        .avatar-styled {
            width: 50px;
            height: 50px;
            line-height: 50px;
        }
    }
`



const ScrollContainerRoot = styled.div<{ guestBannerHeight: number }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 5px;
  height: calc(100vh - 60px - 40px - ${props => props.guestBannerHeight + "px"}); /* topbar + guestBanner */
  overflow-x: hidden;
`

const LabelText = styled.p`
    margin-top: 30px;
    font-size: 13px;
    font-weight: bold;
    line-height: 14px;
    margin-left: 7px;
    font-family: ${branding.font1};
`

const DeleteCurrentPictureButton = styled.div`
    cursor: pointer;
    color: ${branding.dangerButtonColor};
    text-decoration: underline;
    margin-top: 70px;
    font-size: 13px;
    line-height: 14px;
    font-family: ${branding.font1};
`

const WhiteButton = styled(Button)`
    background-color: #fff !important;
    border: 1px solid ${branding.primaryColor} !important;
    box-sizing: border-box;
    padding: 0.55rem 2.5rem;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: ${branding.primaryColor} !important;
    user-select: none;
    border-radius: 1.25rem;
    margin-left: 10px;
    text-align: center;
    height: 40px;

    &:active, &:focus, &:focus:active {
        background-image: none;
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        color: ${branding.primaryColor} !important;
        background-color: #fff;
        border: 1px solid ${branding.primaryColor};
    }
`

const ColoredButton = styled(Button)`
    background-color: ${branding.primaryColor} !important;
    border: 1px solid ${branding.primaryColor};
    box-sizing: border-box;
    padding: 0.65rem 2.5rem;
    margin-left: 20px;
    margin-top: 39px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: #fff;
    user-select: none;
    border-radius: 1.25rem;
    height: 40px;

    &:active, &:focus, &:focus:active {
        background-image: none;
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        color: #fff !important;
        background-color: ${branding.primaryColor};
        border: 1px solid ${branding.primaryColor};
    }
`
interface EditMyProfileProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
    guestBannerHeight: number
}

// Fields that can be disabled through branding -> MyProfilePageBranding -> field editDisabledFields
enum DisabledFields {
    FIRSTNAME = "FIRSTNAME",
    LASTNAME = "LASTNAME",
    LOGO = "LOGO",
    COMPANY = "COMPANY",
    POSITION = "POSITION",
    INDUSTRY = "INDUSTRY",
    COUNTRY = "COUNTRY",
    TIMEZONE = "TIMEZONE",
    BIOGRAPHY = "BIOGRAPHY",
    PHONE = "PHONE",
    MOBILE = "MOBILE",
    FACEBOOK = "FACEBOOK",
    TWITTER = "TWITTER",
    INSTAGRAM = "INSTAGRAM",
    YOUTUBE = "YOUTUBE",
    LINKEDIN = "LINKEDIN",
    XING = "XING",
    PINTEREST = "PINTEREST"
}

interface CategoryData {
    interests: Category[]
    offerings: Category[]
    lookingfor: Category[]

    selectedInterests: Category[]
    selectedOfferings: Category[]
    selectedLookingfor: Category[]
}


const EditMyProfilePageLayout: React.FunctionComponent<EditMyProfileProps> = (props: EditMyProfileProps) => {
    let userState = useLoggedInState()
    const usersPresence = useUsersPresence()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()

    const [firstName, setFirstName] = useState<string>(userState.user()?.firstName || "")
    const [lastName, setLastName] = useState<string>(userState.user()?.lastName || "")
    const [logoUrl, setLogoUrl] = useState<string>(userState.user()?.logoUrl || "")
    const [company, setCompany] = useState<string>(userState.user()?.company || "")
    const [position, setPosition] = useState<string>(userState.user()?.position || "")
    const [biography, setBiography] = useState<string>(userState.user()?.infotext || "")
    const [industry, setIndustry] = useState<string>(userState.user()?.industry || "")
    const [country, setCountry] = useState<string>(userState.user()?.countrycode || "")
    const [languages, setLanguages] = useState<any[]>(userState.user()?.languages || [])

    const [mobile, setMobile] = useState<string>(userState.user()?.mobile || "")
    const [phone, setPhone] = useState<string>(userState.user()?.phone || "")

    const [facebook, setFacebook] = useState<string>(userState.user()?.facebook || "")
    const [twitter, setTwitter] = useState<string>(userState.user()?.twitter || "")
    const [linkedIn, setLinkedIn] = useState<string>(userState.user()?.linkedIn || "")
    const [xing, setXing] = useState<string>(userState.user()?.xing || "")
    const [instagram, setInstagram] = useState<string>(userState.user()?.instagram || "")
    const [youTube, setYouTube] = useState<string>(userState.user()?.youTube || "")
    const [pinterest, setPinterest] = useState<string>(userState.user()?.pinterest || "")

    const [categoryData, setCategoryData] = useState<CategoryData>({ interests: [], offerings: [], lookingfor: [], selectedInterests: [], selectedOfferings: [], selectedLookingfor: [] })

    const [imageDimensions, setImageDimensions] = useState<any>({ height: 0, width: 0 })
    const [imageSize, setImageSize] = useState<number>(0)

    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [isProfileImageChanged, setIsProfileImageChanged] = useState<boolean>(false)
    const [isProfileImageDeleted, setIsProfileImageDeleted] = useState<boolean>(false)

    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>("")

    const [imageFile, setImageFile] = useState<any>(null)

    function getData() {
        getInterest().then(res => {
            if (res.content.interests) {
                const interests: Category[] = res.content.interests ?? []
                const offerings: Category[] = res.content.lookingforandoffering ?? []
                const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                const selectedInterests = interests.filter(i => (userState.user()?.interests ?? []).includes(i.id))
                const selecteOfferings = offerings.filter(i => (userState.user()?.lookingfor ?? []).includes(i.id))
                const selectedLookingfor = lookingfor.filter(i => (userState.user()?.offering ?? []).includes(i.id))
                setCategoryData(
                    {
                        interests: interests,
                        offerings: offerings,
                        lookingfor: lookingfor,
                        selectedInterests: selectedInterests,
                        selectedOfferings: selecteOfferings,
                        selectedLookingfor: selectedLookingfor
                    }
                )
            }
        })
    }

    useEffect(() => {
        getData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData()
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    function areSocialMediaLinksCorrect(): boolean {
        if (facebook.length > 0 && !isSocialMediaLinkCorrect(facebook, SocialMediaType.FACEBOOK)) {
            return false
        }

        if (twitter.length > 0 && !isSocialMediaLinkCorrect(twitter, SocialMediaType.TWITTER)) {
            return false
        }

        if (linkedIn.length > 0 && !isSocialMediaLinkCorrect(linkedIn, SocialMediaType.LINKEDIN)) {
            return false
        }

        if (xing.length > 0 && !isSocialMediaLinkCorrect(xing, SocialMediaType.XING)) {
            return false
        }

        if (instagram.length > 0 && !isSocialMediaLinkCorrect(instagram, SocialMediaType.INSTAGRAM)) {
            return false
        }

        if (youTube.length > 0 && !isSocialMediaLinkCorrect(youTube, SocialMediaType.YOUTUBE)) {
            return false
        }

        if (pinterest.length > 0 && !isSocialMediaLinkCorrect(pinterest, SocialMediaType.PINTEREST)) {
            return false
        }

        return true
    }
    function isCompanyNameCorrect(companyName: string): boolean {

        const companyRegex: RegExp = /^((?![\^!@#$*~ <>?]).)((?![\^!@#$*~<>?]).){0,73}((?![\^!@#$*~ <>?]).)$/g
        return companyRegex.test(companyName.trim())
    }

    function isNameCorrect(name: string): boolean {
        const nameRegex: RegExp = /\d/
        return !(nameRegex.test(name.trim()))
        //checks if name string contains numbers, hence why the opposite value is returned - if string contains numbers, regex return "true", but the function returns "false", meaning that the name is not in the correct format
    }

    function isPhoneNumberCorrect(phoneNumber: string): boolean {

        if (phoneNumber.length > 0) {
            // eslint-disable-next-line
            const phoneNumberRegex: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
            return phoneNumberRegex.test(phoneNumber.trim())
        }

        return true
    }

    function isImageSizeValid(): boolean {
        const minHeight = branding.myProfilePageContent.minPictureHeight ?? 500
        const minWidth = branding.myProfilePageContent.minPictureWidth ?? 500

        if (imageSize > 1000000 || imageDimensions.height < minHeight || imageDimensions.width < minWidth) {
            return false
        }
        return true
    }

    function areRequiredFieldsEmpty(): boolean {
        if (firstName.length > 0 && lastName.length > 0 && company.length > 0 && position.length > 0) {
            return false
        }

        return true
    }

    function isValidationCorrect() {
        if (isNameCorrect(firstName) && isNameCorrect(lastName) && isCompanyNameCorrect(company) && isNameCorrect(position) && isNameCorrect(industry) && isPhoneNumberCorrect(phone) && isPhoneNumberCorrect(mobile) && areSocialMediaLinksCorrect()) {
            return true
        }

        return false
    }


    function trimValues() {
        setFirstName(firstName.trim())
        setLastName(lastName.trim())
        setCompany(company.trim())
        setPosition(position.trim())
        setBiography(biography.trim())
        setIndustry(industry.trim())
        setMobile(mobile.trim())
        setPhone(phone.trim())
        setFacebook(facebook.trim())
        setTwitter(twitter.trim())
        setLinkedIn(linkedIn.trim())
        setXing(xing.trim())
        setInstagram(instagram.trim())
        setYouTube(youTube.trim())
        setPinterest(pinterest.trim())
    }

    function resetValues() {
        setFirstName(userState.user()?.firstName || "")
        setLastName(userState.user()?.lastName || "")
        setLogoUrl(userState.user()?.logoUrl || "")
        setCompany(userState.user()?.company || "")
        setPosition(userState.user()?.position || "")
        setBiography(userState.user()?.infotext || "")
        setIndustry(userState.user()?.industry || "")
        setMobile(userState.user()?.mobile || "")
        setPhone(userState.user()?.phone || "")
        setFacebook(userState.user()?.facebook || "")
        setTwitter(userState.user()?.twitter || "")
        setLinkedIn(userState.user()?.linkedIn || "")
        setXing(userState.user()?.xing || "")
        setInstagram(userState.user()?.instagram || "")
        setYouTube(userState.user()?.youTube || "")
        setPinterest(userState.user()?.pinterest || "")
    }

    function updateValues() {
        const payload: any = {
            id: userState.user()?.profileId,
            firstName: firstName,
            lastName: lastName,
            logoUrl: logoUrl,
            company: company,
            position: position,
            languages: languages,
            areaOfResponsibility: userState.user()?.areaOfResponsibility,
            industry: industry,
            countrycode: country,
            interests: categoryData.selectedInterests.map(i => i.id),
            lookingfor: categoryData.selectedLookingfor.map(i => i.id),
            offering: categoryData.selectedOfferings.map(i => i.id),
            infotext: biography,
            mobile: mobile,
            phone: phone,
            facebook: facebook,
            twitter: twitter,
            linkedIn: linkedIn,
            xing: xing,
            instagram: instagram,
            youTube: youTube,
            pinterest: pinterest,
            presence: userState.user()?.presence!,
            matchActive: userState.user()?.matchActive!,
            type: userState.user()?.type!,
            organizations: userState.user()?.organizations ? userState.user()?.organizations!.map(org => {
                return { "id": org.id, "name": org.name }
            }) : [],
            eventDates: userState.user()?.eventDates ? userState.user()?.eventDates!.map(evtd => {
                return { "id": evtd.id, "name": evtd.name }
            }) : [],
            events: userState.user()?.events ? userState.user()?.events!.map(event => {
                return { "id": event.id, "name": event.name }
            }) : [],
            invitingOrganization: userState.user()?.invitingOrganization ? { id: userState.user()?.invitingOrganization?.id, name: userState.user()?.invitingOrganization?.name } : null,
            visible: userState.user()?.visible ?? false,
            showroomStandby: userState.user()?.showroomStandby ?? false
        }

        setAlertMessage(strings.myProfilePageContent.dataSuccessfullyUpdatedText)

        updateProfileData({ profileId: userState.user()!.profileId, profileData: payload }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)
                setSuccess(false)

                setAlertMessage(strings.myProfilePageContent.backendErrorWarning)

                setShowAlert(true)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const user = userState.user()!
            const userName = [user.firstName, user.middleName, user.lastName].filter(Boolean).join(" ")
            await updateUserValues({ id: user.profileId, name: userName, pictureUrl: user.logoUrl, presenceStatus: usersPresence.getPresenceState(user.profileId), lastConnected: new Date().toISOString() })

            setIsLoaded(true)

            // TODO dont show success dialog for an error response
            setSuccess(true)
            setShowAlert(true)
        })
    }

    function handleDataChange() {
        setIsLoaded(false)

        if (areRequiredFieldsEmpty()) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.requiredFieldsEmptyWarning)

            setShowAlert(true)
            return
        }

        if (isProfileImageChanged) {
            if (!isImageSizeValid()) {
                setIsLoaded(true)

                setSuccess(false)
                setAlertMessage(strings.myProfilePageContent.pictureSizeWarning)

                setShowAlert(true)
                return
            }
        }

        if (!isValidationCorrect()) {
            setIsLoaded(true)

            setSuccess(false)
            setAlertMessage(strings.myProfilePageContent.validationIncorrectWarning)

            setShowAlert(true)
            return
        }


        if (isProfileImageDeleted) {
            userState.setProfileImage("")
            setLogoUrl("")

            deleteProfileImage(userState.user()?.profileId || "").then(res => {
                userState.setProfileImage("")
                setLogoUrl("")
                updateValues()
            }).catch(error => {
                logger.error({ message: "EditMyProfileLayout Error", errorMessage: error.message, errorStack: error.stack });
            })

            return
        }

        else if (isProfileImageChanged) {
            if (imageFile && imageFile !== null) {
                let imageData = new FormData()
                imageData.append("image", imageFile)

                changeProfileImage({ profileId: userState.user()?.profileId || "", data: imageData }).then(res => {
                    if (res.logoUrl) {
                        userState.setProfileImage(res.logoUrl || '')
                        setLogoUrl(res.logoUrl)

                        updateValues()
                    }
                }).catch(error => {
                    logger.error({ message: "EditMyProfileLayout Profile Logo Upload failed", errorMessage: error.message, errorStack: error.stack });
                })
            }

            return
        }

        else {
            updateValues()
        }
    }

    const editProfileBody: JSX.Element = <EditMyProfileRoot>

        <ProfileImage
            logoUrl={logoUrl}
            setLogoUrl={setLogoUrl}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            setIsProfileImageDeleted={setIsProfileImageDeleted}
            setIsProfileImageChanged={setIsProfileImageChanged}
            setImageDimensions={setImageDimensions}
            setImageSize={setImageSize}
            setImageFile={setImageFile}
        />

        <Row className="mb-20">
            <ProfileInformation
                required={true}
                labelText={strings.myProfilePageContent.nameLabel}
                value={firstName}
                setValue={setFirstName}
                checkValue={isNameCorrect(firstName)}
                invalidValueWarning={strings.myProfilePageContent.invalidNameWarning}
                disabledFieldMarker={DisabledFields.FIRSTNAME}
                columnXs={12}
                columnMd={6}
            />

            <ProfileInformation
                required={true}
                labelText={strings.myProfilePageContent.surnameLabel}
                value={lastName}
                setValue={setLastName}
                checkValue={isNameCorrect(lastName)}
                invalidValueWarning={strings.myProfilePageContent.invalidNameWarning}
                disabledFieldMarker={DisabledFields.LASTNAME}
                columnXs={12}
                columnMd={6}
            />
        </Row>

        <Row className="mt-10 mb-20">
            <ProfileInformation
                required={true}
                labelText={strings.myProfilePageContent.jobTitleLabel}
                value={position}
                setValue={setPosition}
                checkValue={isNameCorrect(position)}
                invalidValueWarning={strings.myProfilePageContent.invalidJobTitleWarning}
                disabledFieldMarker={DisabledFields.POSITION}
                columnXs={12}
                columnMd={6}
            />

            <ProfileInformation
                required={true}
                labelText={strings.myProfilePageContent.companyLabel}
                value={company}
                setValue={setCompany}
                checkValue={isCompanyNameCorrect(company)}
                invalidValueWarning={strings.myProfilePageContent.invalidCompanyNameWarning}
                disabledFieldMarker={DisabledFields.COMPANY}
                columnXs={12}
                columnMd={6}
            />
        </Row>

        <Row className="mt-10 mb-20">
            <ProfileInformation
                labelText={strings.myProfilePageContent.industryLabel}
                value={industry}
                setValue={setIndustry}
                checkValue={isNameCorrect(industry)}
                invalidValueWarning={strings.myProfilePageContent.invalidIndustryNameWarning}
                disabledFieldMarker={DisabledFields.POSITION}
            />
            <LanguageSwitcher languages={languages} setLanguages={setLanguages} />
        </Row>

        <Row className="mt-10 mb-20">
            <CountrySwitcher country={country} setCountry={setCountry} />
            <TimeZoneSwitcher />
        </Row>

        <Row className="mt-10 mb-20">
            <ProfileInformation
                labelText={strings.myProfilePageContent.phoneLabel}
                value={phone}
                setValue={setPhone}
                checkValue={isPhoneNumberCorrect(phone)}
                invalidValueWarning={strings.myProfilePageContent.invalidPhoneNumberWarning}
                disabledFieldMarker={DisabledFields.PHONE}
                columnXs={12}
                columnMd={6}
            />


            <ProfileInformation
                labelText={strings.myProfilePageContent.mobileLabel}
                value={mobile}
                setValue={setMobile}
                checkValue={isPhoneNumberCorrect(mobile)}
                invalidValueWarning={strings.myProfilePageContent.invalidPhoneNumberWarning}
                disabledFieldMarker={DisabledFields.PHONE}
                columnXs={12}
                columnMd={6}
            />

        </Row>

        <Row className="mt-10 mb-20">
            <SocialMedia
                labelText={strings.myProfilePageContent.facebookLinkLabel}
                type={SocialMediaType.FACEBOOK}
                link={facebook}
                setLink={setFacebook}
                invalidLinkWarning={strings.myProfilePageContent.invalidFacebookLinkWarning}
                icon={IconFacebook({ fill: branding.sideIconBar.sideIconColorDark })} />

            <SocialMedia
                labelText={strings.myProfilePageContent.twitterLinkLabel}
                type={SocialMediaType.TWITTER}
                link={twitter}
                setLink={setTwitter}
                invalidLinkWarning={strings.myProfilePageContent.invalidTwitterLinkWarning}
                icon={IconTwitter({ fill: branding.sideIconBar.sideIconColorDark })} />
        </Row>

        <Row className="mt-10 mb-20">
            <SocialMedia
                labelText={strings.myProfilePageContent.linkedInLinkLabel}
                type={SocialMediaType.LINKEDIN}
                link={linkedIn}
                setLink={setLinkedIn}
                invalidLinkWarning={strings.myProfilePageContent.invalidLinkedInLinkWarning}
                icon={IconLinkedIn({ fill: branding.sideIconBar.sideIconColorDark })} />

            <SocialMedia
                labelText={strings.myProfilePageContent.xingLinkLabel}
                type={SocialMediaType.XING}
                link={xing}
                setLink={setXing}
                invalidLinkWarning={strings.myProfilePageContent.invalidXingLinkWarning}
                icon={IconXing({ fill: branding.sideIconBar.sideIconColorDark })} />
        </Row>

        <Row className="mt-10 mb-20">
            <SocialMedia
                labelText={strings.myProfilePageContent.instagramLinkLabel}
                type={SocialMediaType.INSTAGRAM}
                link={instagram}
                setLink={setInstagram}
                invalidLinkWarning={strings.myProfilePageContent.invalidInstagramLinkWarning}
                icon={IconInstagram({ fill: branding.sideIconBar.sideIconColorDark })} />

            <SocialMedia
                labelText={strings.myProfilePageContent.youTubeLinkLabel}
                type={SocialMediaType.YOUTUBE}
                link={youTube}
                setLink={setYouTube}
                invalidLinkWarning={strings.myProfilePageContent.invalidYouTubeLinkWarning}
                icon={IconYouTube({ fill: branding.sideIconBar.sideIconColorDark })} />
        </Row>

        <ProfileBiography
            labelText={strings.myProfilePageContent.biographyLabel}
            biography={biography}
            setBiography={setBiography}
        />
        <Row>
            <Col xs={8} md={4} className="d-flex mt-2 justify-content-center"><NetworkingToggle /></Col>
        </Row>
        <>
            {branding.myProfilePageContent.categoriesOrder.map((item, index) => {
                switch (item) {
                    case "INTERESTS":
                        return <ProfileCategories key={index} type={CategoryType.INTERESTS} setCategoryBindings={(newInterests) => setCategoryData({ ...categoryData, selectedInterests: newInterests })} data={categoryData} />

                    case "OFFERS":
                        return <ProfileCategories key={index} type={CategoryType.OFFERS} setCategoryBindings={(newOffers) => setCategoryData({ ...categoryData, selectedOfferings: newOffers })} data={categoryData} limitNumberOfSelectedCategories={true} />

                    case "NEEDS":
                        return <ProfileCategories key={index} type={CategoryType.NEEDS} setCategoryBindings={(newLookingfor) => setCategoryData({ ...categoryData, selectedLookingfor: newLookingfor })} data={categoryData} limitNumberOfSelectedCategories={true} />

                    default: return null
                }

            })}
        </>

        <Row>
            <Col xs={8} md={4} className="d-flex mt-2 justify-content-center">
                <WhiteButton
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                        resetValues()
                        props.setMyProfileMode(MyProfilePageMode.VIEW)
                    }} >{strings.myProfilePageContent.discardChangesButtonText}</WhiteButton>
            </Col>
            <Col xs={8} md={4}>
                <div style={{ visibility: isLoaded ? "hidden" : "visible" }} >
                    <SaveLoader />
                </div>
            </Col>
            <Col xs={8} md={4} className="d-flex justify-content-start">
                <ColoredButton
                    onClick={() => {
                        trimValues()
                        handleDataChange()
                    }}>{strings.myProfilePageContent.saveAndUpdateButtonText}</ColoredButton>
            </Col>
        </Row>
    </EditMyProfileRoot>

    return (

        <div style={{ background: "#fff" }}>
            <ScrollContainerRoot guestBannerHeight={props.guestBannerHeight}>
                <ContentScrollContainer>
                    <Container className="pt-5 pb-20" style={{ position: "relative", backgroundColor: "#fff" }}>
                        <Row style={{ display: "flex !important" }}>
                            <Col xs={4}>
                            </Col>
                            <Col xs={16} md={12}>
                                {editProfileBody}
                            </Col>
                            <Col xs={4} >
                            </Col>
                        </Row>
                    </Container>
                </ContentScrollContainer>
            </ScrollContainerRoot>
            {showAlert && <DataUpdateAlert
                message={alertMessage}
                close={() => setShowAlert(false)}
                show={showAlert}
                type='edit-profile'
                titleImage={success ? IconIndicatorSuccessBlack({ fill: branding.sideIconBar.sideIconColorDark }) : IconIndicatorErrorBlack({ fill: branding.sideIconBar.sideIconColorDark })}
                position='bottom' />}
        </div >
    )
}

export default EditMyProfilePageLayout

/* #endregion */


