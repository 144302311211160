import { createState, useState, self } from "@hookstate/core"
import { PresenceType, getPresenceByUserId, UserResponse, updateUserValues, BackendServiceError, DynamoDBErrors } from "../backendServices/BackendServices"
import { API, graphqlOperation } from "aws-amplify"
import { onUpdateUserById } from "../graphql/subscriptions"
import { defaultLogger as logger } from "../globalStates/AppState"

export const timeIntervalRead = 30 * 60 * 1000

export enum EventType {
    INIT,
    EVENT_BEGIN,
    EVENT_END,
    SELECT,
    DONOTDISTURB_TOGGLE
}
type UsersStates = {
    curState: PresenceType,
    afterEvent: PresenceType | undefined,
    intervalHandle?: number | undefined,
    subscriptionCounter: number
}
interface usersPresence {
    [key: string]: UsersStates
}

interface HandleMap {
    [key: string]: any
}
const handleMap: HandleMap = {}

interface PromiseMap {
    [key: string]: Promise<any>
}
const promiseMap: PromiseMap = {}

export function getCurPresence(presence: PresenceType, lastConnected: string, timeInterval: number) {

    const now = new Date().toISOString()
    const offline = Date.parse(now) - Date.parse(lastConnected) > timeInterval
    return offline ? PresenceType.OFFWORK : presence
}

export const useUsersPresence = createState({} as usersPresence)[self].map(s => () => {
    const presence = useState(s)
    return {
        create: (userId: string, me: boolean, presenceState?: PresenceType, lastConnected?: string) => {
            if (userId && userId !== '') {
                (async () => {
                    if (promiseMap[userId]) await promiseMap[userId]
                    promiseMap[userId] = (async () => {
                        if (presenceState) {
                            if (!presence[self].get()[userId] || presence[self].get()[userId].subscriptionCounter === 0) {

                                let intervalAlive: any = undefined
                                let curPresence = PresenceType.AVAILABLE
                                if (me) {
                                    curPresence = presenceState
                                } else {
                                    intervalAlive = setInterval(async () => {
                                        // is alive
                                        const resp = await getPresenceByUserId(userId)
                                        const user = (resp as UserResponse)?.getUser
                                        const curPresence = getCurPresence(user!.presenceStatus, user!.lastConnected, timeIntervalRead)

                                        if (user!.presenceStatus !== curPresence) {
                                            presence[self].set(newState => {
                                                newState[user!.id].curState = curPresence
                                                return newState
                                            })
                                        }
                                    }, timeIntervalRead)

                                    curPresence = getCurPresence(presenceState, lastConnected as string, timeIntervalRead)
                                }

                                presence[self].set(ns => {
                                    ns[userId] = { curState: curPresence, afterEvent: undefined, intervalHandle: intervalAlive, subscriptionCounter: 0 }
                                    return ns
                                })
                            }
                        } else {
                            presence[self].set(ns => {
                                ns[userId] = { curState: PresenceType.DEFAULT, afterEvent: undefined, intervalHandle: undefined, subscriptionCounter: 0 }
                                return ns
                            })
                        }
                    })()
                })()
            }
        },
        subscribe: (userId: string) => {
            (async () => {
                if (promiseMap[userId]) await promiseMap[userId]
                    promiseMap[userId] = (async () => {
                        presence[self].set(newState => {
                            if(!newState[userId]) {
                                newState[userId] = { curState: PresenceType.DEFAULT, afterEvent: undefined, intervalHandle: undefined, subscriptionCounter: 0 }
                            }

                            if (userId && userId !== '' && presence[self].get()[userId]?.subscriptionCounter === 0) {
                                // TODO use correct type
                                const subscription = (API.graphql(graphqlOperation(onUpdateUserById, { id: userId })) as any).subscribe({
                                    next: (resp: any) => {
                                        const user = resp.value.data.onUpdateUserById
                                        presence[self].set(newState => {
                                            if (newState[user.id]) {
                                                newState[user.id].curState = user.presenceStatus
                                            }
                                            return newState
                                        })
                                    }
                                })
                                handleMap[userId] = subscription
                                presence[self].set(ns => {
                                    ns[userId].subscriptionCounter =  1
                                    return ns
                                })
                            }                        
                            return newState
                        })
                    })()
            })()
        },
        unsubscribe: (userId: string) => {
            (async () => {
                if (promiseMap[userId]) await promiseMap[userId]
                promiseMap[userId] = (async () => {
                    if (userId && userId !== '' && presence[self].get()[userId]?.subscriptionCounter > 0) {
                        const intervalHandle = presence[self].get()[userId].intervalHandle
                        const subscriptionHandle = handleMap[userId]
                        if (presence[self].get()[userId].subscriptionCounter === 1) {
                            handleMap[userId] = undefined;
                            if (subscriptionHandle) {
                                subscriptionHandle.unsubscribe()
                            }
                            if (intervalHandle) {
                                clearInterval(intervalHandle)
                            }
                        }

                        presence[self].set(newState => {
                            newState[userId].subscriptionCounter = presence[self].get()[userId].subscriptionCounter - 1
                            if (presence[self].get()[userId].subscriptionCounter === 0) {
                                newState[userId].intervalHandle = undefined
                            }
                            return newState
                        })
                    }
                })()
            })()
        },
        getPresenceState: (userId?: string) => {
            if (userId && presence[self].get()[userId])
                return presence[self].get()[userId].curState
            else
                return PresenceType.DEFAULT
        },
        updatePresenceState: (userId: string, event: EventType, type?: PresenceType) => {
            if (userId && userId !== '') {
                (async () => {
                    if (promiseMap[userId]) await promiseMap[userId]
                    promiseMap[userId] = (async () => {
                        presence[self].set(newState => {
                            if(!newState[userId]) {
                                newState[userId] = { curState: PresenceType.DEFAULT, afterEvent: undefined, intervalHandle: undefined, subscriptionCounter: 0 }
                            }
                            let presenceType: PresenceType
                            if (event === EventType.INIT) {
                                presenceType = PresenceType.AVAILABLE
                            } else if (event === EventType.SELECT && type) {
                                presenceType = type
                            } else if (event === EventType.EVENT_BEGIN) {
                                newState[userId].afterEvent = (newState[userId].curState === PresenceType.DONOTDISTURB) ? PresenceType.DONOTDISTURB : PresenceType.AVAILABLE
                                presenceType = (newState[userId].curState === PresenceType.DONOTDISTURB) ? PresenceType.DONOTDISTURB : PresenceType.BUSY
                            } else if (event === EventType.EVENT_END) {
                                presenceType = newState[userId].afterEvent ? newState[userId].afterEvent! : (newState[userId].curState === PresenceType.DONOTDISTURB) ? PresenceType.DONOTDISTURB : PresenceType.AVAILABLE
                                newState[userId].afterEvent = undefined
                            } else if (event === EventType.DONOTDISTURB_TOGGLE && type) {
                                if (newState[userId].afterEvent === undefined)
                                    presenceType = type
                                else {
                                    if (type === PresenceType.DONOTDISTURB) {
                                        presenceType = type
                                        newState[userId].afterEvent = type
                                    } else {
                                        presenceType = PresenceType.BUSY
                                        newState[userId].afterEvent = PresenceType.AVAILABLE
                                    }
                                }
                            }
                            newState[userId].curState = presenceType!;

                            (async () => {
                                    const respUpdate = await updateUserValues({ id: userId, presenceStatus: presenceType!, lastConnected: new Date().toISOString() })

                                    if ((respUpdate as BackendServiceError).httpStatus) {
                                        logger.error(respUpdate)
                                    } else if ((respUpdate as DynamoDBErrors)?.errors) {
                                        (respUpdate as DynamoDBErrors).errors.map(error => logger.error(error))
                                    }
                            })()
                            return newState
                        })
                    })()
                })()
            }
        }
    }
})
