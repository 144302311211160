import React, { useState } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import { IconCloseFilled, IconPlayFilled } from "../../ui/Icons"

const CloseIcon = styled.div`
    position: absolute;
    right: -25px;
    top: -60px;
    cursor: pointer;
`
const VideoPlayerIcon = styled.div`
    cursor: pointer;
    padding: 30px;
    border-radius: 50%;
    border: 3px solid white;
`

const VideoModalRoot = styled.div<{ width?: string, headerOpen?: boolean }>`
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    left: ${props => `calc(100% - 5% - ${props.headerOpen ? props.width : `calc(${props.width} - 5%)` || '300px'})`};
    display: flex;
    width: ${props => props.width ? props.headerOpen ? props.width : `calc(${props.width} - 5%)` : '300px'};
    padding-bottom: ${props => `calc(9 / 16 * ${props.headerOpen ? props.width : `calc(${props.width} - 5%)` || '300px'})`};
    transition: width 1s, padding-bottom 1s, left 1s;

    &.center-video {
        top: 50vh;
        left: calc(10% + 60px);
        width: calc(80% - 60px);
        padding-bottom: calc(9 / 16 * 80%);
    }


    /* Invisible layer under the modal that will not allow user to scroll when the video is active */
    &.center-video::before {
        content: '';
        display: block;
        position: absolute;
        left: calc(-10% - 120px);
        top: -50%;
        width: 200vw;
        height: 200vh;
        z-index: 0;
        background-color: rgba(0, 0, 0, 0);
    }
`

const VideoThumbnailContainer = styled.div<{ bgImage: string }>`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-image: ${props => `url(${props.bgImage})`};
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ReactPlayerContainer = styled.div`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    & video { 
        outline: none !important;
    }
`


const VideoThumbnail = (bgImage: string, onPlay: React.Dispatch<React.SetStateAction<boolean>>) => {
    return (
        <VideoThumbnailContainer bgImage={bgImage}>
            <VideoPlayerIcon onClick={() => onPlay(true)}>
                {IconPlayFilled({ width: "35", height: "35", stroke: "white" })}
            </VideoPlayerIcon>
        </VideoThumbnailContainer>
    )
}

const VideoPlayer = (videoUrl: string) => {
    return (
        <ReactPlayerContainer>
            <ReactPlayer controls={true} playing={true} url={videoUrl} width="100%" height="100%" style={{ outline: 'none' }} config={{
                file: {
                    attributes: {
                        controlsList: "nodownload",
                        onContextMenu: (e: any) => e.preventDefault()
                    }
                }
            }}></ReactPlayer>
        </ReactPlayerContainer>
    )
}


interface VideoModalProps {
    videoUrl: string
    videoImageUrl: string
    width?: string
    rootCss?: React.CSSProperties
    headerOpen?: boolean
}


export const VideoModal = (props: VideoModalProps) => {
    const [showVideo, setShowVideo] = useState(false)



    return (
        <VideoModalRoot width={props.width} style={props.rootCss} className={showVideo ? 'center-video' : ''} headerOpen={props.headerOpen} >
            {showVideo && <CloseIcon onClick={() => setShowVideo(false)}>
                {IconCloseFilled({ width: "70", height: "70", fill: "transparent" })}
            </CloseIcon>}

            {showVideo && VideoPlayer(props.videoUrl)}
            {!showVideo && VideoThumbnail(props.videoImageUrl, setShowVideo)}

        </VideoModalRoot>
    )
}