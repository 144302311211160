import React from "react"
import styled from "styled-components"
import { useFavoriteState } from "../globalStates/Favorites"
import { syncFavorites } from "../backendServices/BackendServices"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import moment from "moment"
import { IconBookmark, IconBookmarkFilled } from "./Icons"
//import { useAppState } from "../globalStates/AppState"
import { EntityType } from "../backendServices/Types"
import branding from "../branding/branding"


const ActionIcon = styled.div`
   position:absolute;
   cursor: pointer;
`
interface FavIconProps {
    color: string
    right: string
    fontSize: string
    left: string
    top: string
    marginleft: string
    marginright: string
}
const FavIcon = styled(ActionIcon) <FavIconProps>`
   color: ${props => props.color};
   right: ${props => props.right};
   font-size: ${props => props.fontSize};
   left: ${props => props.left};
   top: ${props => props.top};
   margin-left: ${props => props.marginleft};
   margin-top: ${props => props.marginright};
`

const NewFavIcon = styled.div`
    cursor: pointer;
`

interface BookmarkWithToggleProps {
    type: EntityType
    id: string
    name: string
    favIconNone?: boolean
    favIconBasic?: boolean
    color?: string
    stroke?: string
    fontSize?: string
    newBookmarkItem?: boolean,
    className?: string
    onToggle?: Function
}

export const BookmarkWithToggle: React.FC<BookmarkWithToggleProps> = (props) => {
    const favoriteState = useFavoriteState()
    //const appState = useAppState()
    const userState = useLoggedInState()

    const profileId = userState.user()?.profileId
    let date = new Date()
    date.setMinutes(date.getMinutes() - 1)

    function syncFav() {
        if (profileId) {
            syncFavorites({
                profileId: profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00", 
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [{
                        id: props.id,
                        kind: props.type.toUpperCase(),
                        deleted: favoriteState.is(props.type, props.id) ? false : true,
                        lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    }]
                }
            }).then((resp) => {
                favoriteState.setLastSyncTime(new Date())
                //appState.setBookmarkNotificationName(props.name)
                //appState.setShowBookmarkNotification(favoriteState.is(props.type, props.id))
            }).catch((e: { message: React.SetStateAction<string> }) => {
                // syncFavorites failed, logged in BackendServices
            })
        }
    }
    const isNone = props.favIconNone
    const color = props.color ? props.color : props.type !== "organization" ? "#969696" : props.favIconBasic ? branding.sideIconBar.sideIconColorDark : "#FFFFFF"
    const icon1 = props.type !== "organization" ? IconBookmarkFilled({fill: color}) : IconBookmarkFilled({fill: color})
    const icon2 = props.type !== "organization" ? IconBookmark({fill: color}) : IconBookmark({fill: color})
    const top = props.type !== "organization" || !isNone ? "0" : "50%"
    const left = props.type !== "organization" || !isNone ? "" : "50%"
    const right = props.type !== "organization" || !isNone ? "5px" : ""
    const marginleft = props.type !== "organization" || !isNone ? "0" : "-50px"
    const marginright = props.type !== "organization" || !isNone ? "0" : "-50px"
    const fontSize = props.fontSize ? props.fontSize : props.type !== "organization" || !isNone ? "20px" : "80px"

    function toggleFavoriteState(event: any) {

        favoriteState.toggle(props.type, props.id)
        syncFav()
        event.preventDefault()
        event.stopPropagation()
        if (props.onToggle) {
            props.onToggle(props.id)
        }
    }


    return <>
        {props.newBookmarkItem ?
            <NewFavIcon className={props.className} onClick={event => {
                toggleFavoriteState(event)
            }}>
                {favoriteState.is(props.type, props.id) ? icon1 : icon2}
            </NewFavIcon>
            :
            <FavIcon
                color={color}
                top={top}
                left={left}
                right={right}
                marginleft={marginleft}
                marginright={marginright}
                fontSize={fontSize}
                className={props.className}
                onClick={event => {
                    toggleFavoriteState(event)
                }}>
                {favoriteState.is(props.type, props.id) ? icon1 : icon2}
            </FavIcon>}
    </>
}

export default BookmarkWithToggle
